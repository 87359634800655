import MaterialIcon from "../../../components/MaterialIcon/MaterialIcon";

function Footer({
  devEnvironment,
  downloadDiagnostics,
  performTestCall,
}: {
  devEnvironment: boolean;
  downloadDiagnostics: () => void;
  performTestCall: () => void;
}): JSX.Element {
  return (
    <div className="h-14 bg-white w-full border-t flex flex-row justify-end items-center px-6 gap-2">
      <div className="flex-1 text-jura-chrome-15">v1.0</div>
      {devEnvironment ? (
        <div
          className="flex items-center justify-center cursor-pointer hover:bg-jura-chrome-15 transition p-2 rounded-sm"
          onClick={performTestCall}
        >
          <MaterialIcon icon="phone" />
        </div>
      ) : null}
      <div>{/* Dummy */}</div>
      {/* Diagnostics Button */}
      <div
        className="flex items-center justify-center cursor-pointer hover:bg-jura-chrome-15 transition p-2 rounded-sm"
        onClick={downloadDiagnostics}
      >
        <MaterialIcon icon="download" />
      </div>
    </div>
  );
}

export default Footer;
