import css from "./UnderlineTitle.module.css";

interface UnderlineTitleProps {
  text: string;
}
function UnderlineTitle(props: UnderlineTitleProps): JSX.Element {
  return (
    <div>
      <div className=" text-jura-brown-60 font-extrabold text-xl">
        {props.text}
      </div>
      <div className=" bg-jura-brown-60 h-2 w-5"></div>
    </div>
  );
}

export default UnderlineTitle;
