function makeCoffeeMachineImageUrl(
  articleNumber: string,
  resolution: CoffeeMachineImageResolution = CoffeeMachineImageResolution.res512x512
): string {
  var resString = (() => {
    switch (resolution) {
      case CoffeeMachineImageResolution.res256x256:
        return "256x256";
      case CoffeeMachineImageResolution.res512x512:
        return "512x512";
      case CoffeeMachineImageResolution.res1024x1024:
        return "1024x1024";
    }
  })();

  return `https://jura-storage.fra1.cdn.digitaloceanspaces.com/coffee-machine-images/${resString}/${articleNumber}_front_${resString}px.png`;
}

export enum CoffeeMachineImageResolution {
  res256x256,
  res512x512,
  res1024x1024,
}

export default makeCoffeeMachineImageUrl;
