import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HttpStatusCode,
} from "axios";
import { ITokenService } from "../tokenService/ITokenService";

export abstract class ISightcallTokenService {
  abstract getSsoToken(user: string): Promise<string>;
}

export class SightcallTokenService implements ISightcallTokenService {
  private _host: string;
  private _axiosInstance: AxiosInstance;
  private _tokenService: ITokenService;

  constructor(host: string, tokenService: ITokenService) {
    //remove backslashes from host
    while (host.endsWith("/")) {
      host = host.substring(0, host.length - 1);
    }
    this._host = host;
    this._tokenService = tokenService;
    this._axiosInstance = axios.create();
  }

  private async _request<T>(
    config: AxiosRequestConfig<T>
  ): Promise<AxiosResponse> {
    //perform request
    try {
      let response = await this._axiosInstance.request(config);

      //if not unauthorized, return
      if (response.status != HttpStatusCode.Unauthorized) {
        return response;
      }

      //if unauthorized, refresh the access token
      await this._tokenService.updateAccessToken();

      //Change the config to use the new access token
      var newConfig = {
        ...config,
        header: {
          ...config.headers,
          Authorization: `Bearer ${this._tokenService.accessToken}`,
        },
      };

      //perform new request
      let newResponse = await this._axiosInstance.request(newConfig);

      return newResponse;
    } catch (e) {
      throw e;
    }
  }

  public async getSsoToken(user: string): Promise<string> {
    var request: AxiosRequestConfig = {
      method: "POST",
      url: `${this._host}/api/sightcall/get-sso-token`,
      data: { email: user },
      headers: {
        Authorization: `Bearer ${this._tokenService.accessToken}`,
        "Content-Type": `application/json`,
      },
    };

    let response = await this._request(request);

    if (response.status == HttpStatusCode.Ok) {
      //Validate the response data
      var bearerToken = this._validateGetSightcallSSOTokenResponse(response);

      //Return the data
      return bearerToken;
    }

    throw "Invalid response";
  }

  private _validateGetSightcallSSOTokenResponse(
    response: AxiosResponse
  ): string {
    if (response.data?.id == null || response.data?.id == undefined) {
      throw "Invalid Response";
    }

    return response.data.id;
  }
}
