import { useTranslation } from "react-i18next";
import { AppState } from "../../../Application";
import MaterialIcon from "../../../components/MaterialIcon/MaterialIcon";
import CoffeeMachineReadout from "../../../features/joeApi/models/coffeeMachineReadout";
import JoeReadout from "../../../features/joeApi/models/joeReadout";
import JoeUser, {
  ContactInformation,
} from "../../../features/joeApi/models/joeUser";
import { CoffeeMachineTemplate } from "../../../features/coffeeMachineTemplates/CoffeeMachineTemplate";
import { useState } from "react";
import machineGridIcon from "./machine_gridicon.png";
import joeGridIcon from "./joe_gridicon.png";

import { ITemplateService } from "../../../features/coffeeMachineTemplates/TemplateService";
import CoffeeStrengthSlider from "../../../components/CoffeeStrengthSlider/CoffeeStrengthSlider";
import OptionsDisplay from "../../../components/OptionsDisplay/OptionsDisplay";
import RangeDisplay from "../../../components/RangeDisplay/RangeDisplay";
import makeCoffeeMachineImageUrl, {
  CoffeeMachineImageResolution,
} from "../../../features/coffeeMachineImages/makeCoffeeMachineImageUrl";

export interface Props {
  user: JoeUser | null;
  coffeeMachine: {
    readout: CoffeeMachineReadout;
    template: CoffeeMachineTemplate;
  } | null;
  joe: {
    readout: JoeReadout;
    template: CoffeeMachineTemplate; //
  } | null;
  otherCoffeeMachines: {
    readout: CoffeeMachineReadout;
    template: CoffeeMachineTemplate;
  }[];
  templateService: ITemplateService;
}

/**
 * Shows all the data related to the call.
 * @param param0
 * @returns
 */
function Call({
  user,
  coffeeMachine,
  joe,
  otherCoffeeMachines,
  templateService,
}: Props): JSX.Element {
  console.log(joe?.readout);

  return (
    <div className="flex flex-col w-full h-full min-w-[25rem] bg-jura-cream gap-2 pl-2">
      <div>
        <CallHeaderArea user={user} />
      </div>
      <div className="flex-1">
        <CallDataArea
          user={user}
          coffeeMachine={coffeeMachine}
          joe={joe}
          otherCoffeeMachines={otherCoffeeMachines}
          templateService={templateService}
        />
      </div>
    </div>
  );
}

/**
 * Shows information about the user, if available
 * @param param0
 * @returns
 */
function CallHeaderArea({ user }: { user: JoeUser | null }) {
  if (user != null) {
    return <CallHeader user={user} />;
  } else {
    return <CallHeaderNull />;
  }
}
function CallHeader({ user }: { user: JoeUser }) {
  return (
    <div className="flex flex-col gap-2 bg-white p-2">
      <div className=" flex flex-row items-center px-2 gap-2 ">
        <MaterialIcon icon="person" />
        <div>{user.firstName}</div>
        <div>{user.lastName}</div>
      </div>
      <div className="flex flex-row items-center px-2 gap-2">
        <MaterialIcon icon="email" />
        <div>{user.email}</div>
      </div>
      {user.contactInformation.length != 0 ? (
        <div className="flex flex-row items-center px-2 gap-2 mt-4">
          <ContactInformations contactInformation={user.contactInformation} />
        </div>
      ) : null}
    </div>
  );
}

function ContactInformations({
  contactInformation,
}: {
  contactInformation: ContactInformation[];
}) {
  const [addressIndex, setAddressIndex] = useState<number>(0);

  let safeIndex = addressIndex;
  safeIndex = safeIndex < 0 ? 0 : safeIndex;
  safeIndex =
    safeIndex > contactInformation.length - 1
      ? contactInformation.length - 1
      : safeIndex;

  if (addressIndex != safeIndex) {
    setAddressIndex(safeIndex);
  }

  const nextIndex = () => {
    setAddressIndex(addressIndex + 1);
  };
  const previousIndex = () => {
    setAddressIndex(addressIndex - 1);
  };

  let selectedContactInformation = null;
  if (contactInformation.length != 0) {
    selectedContactInformation = contactInformation[safeIndex];
  }

  return (
    <div className="flex flex-row w-full items-center gap-2">
      <div
        className="w-6 cursor-pointer select-none"
        onClick={() => previousIndex()}
      >
        <MaterialIcon
          icon="chevron_left"
          style={{ opacity: safeIndex != 0 ? "1.0" : "0.3" }}
        />
      </div>
      <div className="flex-1">
        {selectedContactInformation != null ? (
          <div>
            <div>{selectedContactInformation.street}</div>
            <div>
              {selectedContactInformation.zipCode}{" "}
              {selectedContactInformation.city}
            </div>
            <div>
              {selectedContactInformation.state}{" "}
              {selectedContactInformation.country}
            </div>
          </div>
        ) : null}
      </div>
      <div
        className="w-6 cursor-pointer select-none"
        onClick={() => nextIndex()}
      >
        <MaterialIcon
          icon="chevron_right"
          style={{
            opacity: safeIndex != contactInformation.length - 1 ? "1.0" : "0.3",
          }}
        />
      </div>
    </div>
  );
}

function CallHeaderNull() {
  const [t, i18n] = useTranslation("translation", { keyPrefix: "callerData" });
  return (
    <div className="bg-white p-2 text-jura-chrome-15 flex justify-center items-center">
      {t("No User Data")}
    </div>
  );
}

/**
 * This is the are where all the call data is display. It shows either a grid with available data,
 * or a data browser for each data set
 * @param param0
 * @returns
 */
function CallDataArea({
  user,
  coffeeMachine,
  joe,
  otherCoffeeMachines,
  templateService,
}: Props) {
  const [content, setContent] = useState<JSX.Element | null>(null);

  return (
    <div className="h-full w-full">
      {content == null ? (
        <DataGrid
          items={[
            coffeeMachine != null ? (
              <CoffeeMachineDataGridItem
                key={0}
                readout={coffeeMachine.readout}
                onClick={() => {
                  setContent(
                    <MachineDataBrowser
                      readout={coffeeMachine.readout}
                      template={coffeeMachine.template}
                      onClose={() => setContent(null)}
                      templateService={templateService}
                    />
                  );
                }}
              />
            ) : null,
            joe != null ? (
              <JoeDataGridItem
                key={1}
                readout={joe.readout}
                onClick={() => {
                  setContent(
                    <JoeDataBrowser
                      readout={joe.readout}
                      template={joe.template}
                      templateService={templateService}
                      onClose={() => setContent(null)}
                    />
                  );
                }}
              />
            ) : null,
            ...otherCoffeeMachines.map((i) => {
              return (
                <CoffeeMachineDataGridItem
                  key={i.readout.uniqueId}
                  readout={i.readout}
                  onClick={() => {
                    setContent(
                      <MachineDataBrowser
                        readout={i.readout}
                        template={i.template}
                        onClose={() => setContent(null)}
                        templateService={templateService}
                      />
                    );
                  }}
                />
              );
            }),
          ].flatMap((e) => (e != null ? [e] : []))}
        />
      ) : (
        content
      )}
    </div>
  );
}

/**
 * This is the data browser for machine data
 * @param param0
 * @returns
 */
function MachineDataBrowser({
  onClose,
  readout,
  template,
  templateService,
}: {
  onClose: () => void;
  readout: CoffeeMachineReadout;
  template: CoffeeMachineTemplate;
  templateService: ITemplateService;
}) {
  const [t, i18n] = useTranslation("translation", { keyPrefix: "callerData" });
  return (
    <DataBrowser
      pages={[
        {
          title: t("Dashboard"),
          content: (
            <MachineDataDashboardPage
              readout={readout}
              template={template}
              templateService={templateService}
            />
          ),
        },
        {
          title: t("Products"),
          content: (
            <MachineDataProductPage
              readout={readout}
              template={template}
              templateService={templateService}
            />
          ),
        },
        {
          title: t("Settings"),
          content: (
            <MachineDataSettingsPage
              template={template}
              templateService={templateService}
              settings={readout.settings}
            />
          ),
        },
      ]}
      header={<UpdatedOn date={readout.timestamp} />}
      onClose={onClose}
    />
  );
}

function MachineDataSettingsPage({
  settings,
  template,
  templateService,
}: {
  settings: CoffeeMachineReadout.Setting[];
  template: CoffeeMachineTemplate;
  templateService: ITemplateService;
}) {
  const [t, i18n] = useTranslation("translation");
  const tt = (text: string) => templateService.t(text, i18n.language);
  return (
    <div className="gap-4">
      {settings.map((setting) => {
        let switchSetting = template.MACHINESETTINGS?.SWITCH?.find(
          (s) => s["@_P_Argument"] == setting.id
        );
        if (switchSetting != undefined) {
          let templateValue = switchSetting.ITEM.find(
            (item) => item["@_Value"] == setting.value
          );
          if (templateValue != undefined) {
            let name = templateService.t(
              switchSetting["@_Text"],
              i18n.language
            );
            let value = templateService.t(
              templateValue["@_Text"],
              i18n.language
            );
            return (
              <SettingListItem
                setting={{ name: name, value: value }}
                key={setting.id}
              />
            );
          }
        }

        let comboboxSetting = template.MACHINESETTINGS?.COMBOBOX?.find(
          (s) => s["@_P_Argument"] == setting.id
        );
        if (comboboxSetting != undefined) {
          let templateValue = comboboxSetting.ITEM.find(
            (item) => item["@_Value"] == setting.value
          );
          if (templateValue != undefined) {
            let name = templateService.t(
              comboboxSetting["@_Text"],
              i18n.language
            );
            let value = templateService.t(
              templateValue["@_Text"],
              i18n.language
            );
            return (
              <SettingListItem
                setting={{ name: name, value: value }}
                key={setting.id}
              />
            );
          }
        }

        let sliderSetting = template.MACHINESETTINGS?.SLIDER?.find(
          (s) => s["@_P_Argument"] == setting.id
        );
        if (sliderSetting != undefined) {
          let name = tt(sliderSetting["@_Text"]);

          //Its a range slider, just display the value
          if ((sliderSetting.ITEM?.length ?? 0) == 0) {
            let value = parseInt(setting.value, 16).toString();
            return (
              <SettingListItem
                setting={{ name: name, value: value }}
                key={setting.id}
              />
            );
          }
          //Its a value slider, find an item and use its translated name
          else {
            console.log("no here");
            let textValue = sliderSetting.ITEM?.find(
              (s) => s["@_Value"] == setting.value
            )?.["@_Text"];

            if (textValue != undefined) {
              return (
                <SettingListItem
                  setting={{ name: name, value: tt(textValue) }}
                  key={setting.id}
                />
              );
            }
          }
        }

        return null;
      })}
    </div>
  );
}

function SettingListItem({
  setting,
}: {
  setting: { name: string; value: string };
}) {
  return (
    <div className="flex flex-row justify-between">
      <div className="font-bold text-sm">{setting.name}</div>
      <div className=" text-sm"> {setting.value}</div>
    </div>
  );
}

function MachineDataProductPage({
  readout,
  template,
  templateService,
}: {
  readout: CoffeeMachineReadout;
  template: CoffeeMachineTemplate;
  templateService: ITemplateService;
}) {
  const [t, i18n] = useTranslation("translation");

  var sortedProducts = readout.products
    .slice(0)
    .sort((a, b) => (a.consumptions > b.consumptions ? -1 : 1));

  const getProductDetails = (
    productId: string,
    readout: CoffeeMachineReadout,
    template: CoffeeMachineTemplate
  ) => {
    var product = readout.products.find((product) => product.id == productId);
    var templateProduct = template.PRODUCTS.PRODUCT.find(
      (product) => product["@_Code"] == productId
    );

    if (product == undefined || templateProduct == undefined) {
      return null;
    }

    var props: ProductDetailProps = {
      productTitleKey: templateProduct["@_Text"],
      grinderRatio: null,
      grinderFreeness: null,
      coffeeStrength: null,
      waterAmount: null,
      temperature: null,
      milkAmount: null,
      milkTemperature: null,
      milkFoamAmount: null,
      milkFoamTemperature: null,
      milkBreak: null,
      stroke: null,
      templateService: templateService,
    };

    // Grinder Ratio
    var templateGrinderRatio = templateProduct.GRINDER_RATIO;
    var grinderRatioValue =
      product.parameters.find(
        (p) => p.id == templateProduct!.GRINDER_RATIO?.["@_Argument"]
      )?.value ?? null;
    if (templateGrinderRatio != null && grinderRatioValue != null) {
      props.grinderRatio = {
        title: templateGrinderRatio["@_Text"],
        index: templateGrinderRatio.ITEM.findIndex(
          (item) => item["@_Value"] == grinderRatioValue
        ),
        options: templateGrinderRatio.ITEM.map((item) => item["@_Text"]),
      };
    }

    // Grinder Freeness
    var templateGrinderFreeness = templateProduct.GRINDER_FREENESS;
    var grinderFreenessValue =
      product.parameters.find(
        (p) => p.id == templateProduct!.GRINDER_FREENESS?.["@_Argument"]
      )?.value ?? null;

    if (templateGrinderFreeness != null && grinderFreenessValue != null) {
      props.grinderFreeness = {
        title: templateGrinderFreeness["@_Text"],
        index: templateGrinderFreeness.ITEM.findIndex(
          (item) => item["@_Value"] == grinderFreenessValue
        ),
        options: templateGrinderFreeness.ITEM.map((item) => item["@_Text"]),
      };
    }

    // Coffee Strength
    var templateCoffeeStrength = templateProduct.COFFEE_STRENGTH;
    var coffeeStrengthValue =
      product.parameters.find(
        (p) => p.id == templateProduct!.COFFEE_STRENGTH?.["@_Argument"]
      )?.value ?? null;

    if (coffeeStrengthValue != null && templateCoffeeStrength != null) {
      props.coffeeStrength = {
        title: templateCoffeeStrength["@_Text"],
        index: templateCoffeeStrength.ITEM.findIndex(
          (item) => item["@_Value"] == coffeeStrengthValue
        ),
        levels: templateCoffeeStrength.ITEM.length,
      };
    }

    // Water Amount
    var templateWaterAmount = templateProduct.WATER_AMOUNT;
    var waterAmountValue =
      product.parameters.find(
        (p) => p.id == templateProduct!.WATER_AMOUNT?.["@_Argument"]
      )?.value ?? null;
    if (templateWaterAmount != null && waterAmountValue != null) {
      props.waterAmount = {
        title: templateWaterAmount["@_Text"],
        min: parseInt(templateWaterAmount["@_Min"]),
        max: parseInt(templateWaterAmount["@_Max"]),
        value: parseInt(waterAmountValue, 16),
      };
    }

    // Temperature
    var templateTemperature = templateProduct.TEMPERATURE;
    var temperatureValue =
      product.parameters.find(
        (p) => p.id == templateProduct!.TEMPERATURE?.["@_Argument"]
      )?.value ?? null;

    if (templateTemperature != null && temperatureValue != null) {
      props.temperature = {
        title: templateTemperature["@_Text"],
        index: templateTemperature.ITEM.findIndex(
          (item) => item["@_Value"] == temperatureValue
        ),
        options: templateTemperature.ITEM.map((item) => item["@_Text"]),
      };
    }

    // Milk Amount
    var templateMilkAmount = templateProduct.MILK_AMOUNT;
    var milkAmountValue =
      product.parameters.find(
        (p) => p.id == templateProduct!.MILK_AMOUNT?.["@_Argument"]
      )?.value ?? null;
    if (templateMilkAmount != null && milkAmountValue != null) {
      props.milkAmount = {
        title: templateMilkAmount["@_Text"],
        min: parseInt(templateMilkAmount["@_Min"]),
        max: parseInt(templateMilkAmount["@_Max"]),
        value: parseInt(milkAmountValue, 16),
      };
    }

    //Milk temperature
    var templateMilkTemperature = templateProduct.MILK_TEMP;
    var milkTemperatureValue =
      product.parameters.find(
        (p) => p.id == templateProduct!.MILK_TEMP?.["@_Argument"]
      )?.value ?? null;

    if (templateMilkTemperature != null && milkTemperatureValue != null) {
      props.milkTemperature = {
        title: templateMilkTemperature["@_Text"],
        min: parseInt(templateMilkTemperature["@_Min"]),
        max: parseInt(templateMilkTemperature["@_Max"]),
        value: parseInt(milkTemperatureValue, 16),
      };
    }

    //Milk foam amount
    var templateMilkFoamAmount = templateProduct.MILK_FOAM_AMOUNT;
    var milkFoamAmountValue =
      product.parameters.find(
        (p) => p.id == templateProduct!.MILK_FOAM_AMOUNT?.["@_Argument"]
      )?.value ?? null;
    if (templateMilkFoamAmount != null && milkFoamAmountValue != null) {
      props.milkFoamAmount = {
        title: templateMilkFoamAmount["@_Text"],
        min: parseInt(templateMilkFoamAmount["@_Min"]),
        max: parseInt(templateMilkFoamAmount["@_Max"]),
        value: parseInt(milkFoamAmountValue, 16),
      };
    }

    //Milk Foam Temperature
    var templateMilkFoamTemperature = templateProduct.MILK_FOAM_TEMP;
    var milkFoamTemperatureValue =
      product.parameters.find(
        (p) => p.id == templateProduct!.MILK_FOAM_TEMP?.["@_Argument"]
      )?.value ?? null;
    if (
      templateMilkFoamTemperature != null &&
      milkFoamTemperatureValue != null
    ) {
      props.milkFoamTemperature = {
        title: templateMilkFoamTemperature["@_Text"],
        min: parseInt(templateMilkFoamTemperature["@_Min"]),
        max: parseInt(templateMilkFoamTemperature["@_Max"]),
        value: parseInt(milkFoamTemperatureValue, 16),
      };
    }

    //Milk Break
    var templateMilkBreak = templateProduct.MILK_BREAK;
    var milkBreakValue =
      product.parameters.find(
        (p) => p.id == templateProduct!.MILK_BREAK?.["@_Argument"]
      )?.value ?? null;
    if (templateMilkBreak != null && milkBreakValue != null) {
      props.milkBreak = {
        title: templateMilkBreak["@_Text"],
        min: parseInt(templateMilkBreak["@_Min"]),
        max: parseInt(templateMilkBreak["@_Max"]),
        value: parseInt(milkBreakValue, 16),
      };
    }

    //Stroke
    var templateStrokeValue = templateProduct.STROKE;
    var strokeValue =
      product.parameters.find(
        (p) => p.id == templateProduct!.STROKE?.["@_Argument"]
      )?.value ?? null;
    if (templateStrokeValue != null && strokeValue != null) {
      props.stroke = {
        title: templateStrokeValue["@_Text"],
        min: parseInt(templateStrokeValue["@_Min"]),
        max: parseInt(templateStrokeValue["@_Max"]),
        value: parseInt(strokeValue, 16),
      };
    }

    return props;
  };

  const [productDetails, setProductDetails] =
    useState<ProductDetailProps | null>(null);

  return (
    <div
      className={[
        "h-full w-full relative",
        productDetails == null ? " overflow-y-scroll" : "overflow-y-hidden",
      ].join("")}
    >
      {productDetails != null ? (
        <div
          className="absolute w-full h-full bg-black bg-opacity-40 p-8"
          onClick={() => setProductDetails(null)}
        >
          <div className="bg-white w-full h-full">
            <ProductDetail {...productDetails} />
          </div>
        </div>
      ) : null}

      {sortedProducts.map((product) => {
        var templateProduct = template.PRODUCTS.PRODUCT.find(
          (p) => p["@_Code"] == product.id
        );

        if (templateProduct == undefined) {
          return null;
        }

        return (
          <ProductListItem
            name={templateService.t(templateProduct["@_Text"], i18n.language)}
            customName={null}
            consumptions={product.consumptions}
            img={"/assets/images/" + templateProduct["@_PictureIdle"]}
            preselections={[]}
            onClick={() => {
              setProductDetails(
                getProductDetails(product.id, readout, template)
              );
            }}
            key={product.id}
          />
        );
      })}
    </div>
  );
}

function ProductListItem({
  name,
  customName,
  consumptions,
  img,
  preselections,
  onClick,
}: {
  name: string;
  consumptions: number | null;
  customName: string | null;
  img: string;
  preselections: (
    | "xtrashot"
    | "coldbrew"
    | "sweetfoam"
    | "fakesweetfoam"
    | "double"
    | "powder"
  )[];
  onClick: () => void;
}) {
  return (
    <div
      className="flex flex-row items-center py-2 gap-2 px-2 border-b hover:bg-jura-chrome-15 transition"
      onClick={() => onClick()}
    >
      <div className="h-10 w-10 py-2">
        <img className="h-full w-full object-contain" src={img}></img>
      </div>
      <div className="text-sm">{name}</div>
      {customName == null || customName == "" ? null : (
        <div className="text-sm italic">({customName})</div>
      )}
      <div className="flex-1"></div>
      <div>
        {preselections.map((p) => {
          if (p == "powder") {
            return <div className="h-4 w-4 rounded-full bg-orange-600"></div>;
          } else if (p == "coldbrew") {
            return <div className="h-4 w-4 rounded-full bg-blue-600"></div>;
          } else if (p == "xtrashot") {
            return (
              <div className="h-4 w-4 rounded-full bg-jura-brown-100"></div>
            );
          } else if (p == "double") {
            return <div className="h-4 w-4 rounded-full bg-orange-600"></div>;
          } else if (p == "sweetfoam") {
            return <div className="h-4 w-4 rounded-full bg-pink-600"></div>;
          } else if (p == "fakesweetfoam") {
            return <div className="h-4 w-4 rounded-full bg-pink-600"></div>;
          }
        })}
      </div>
      <div>{consumptions}</div>
    </div>
  );
}

export interface ProductDetailProps {
  productTitleKey: string;
  grinderRatio: {
    title: string;
    options: string[];
    index: number;
  } | null;
  grinderFreeness: {
    title: string;
    options: string[];
    index: number;
  } | null;
  coffeeStrength: {
    title: string;
    levels: number;
    index: number;
  } | null;
  waterAmount: {
    title: string;
    min: number;
    max: number;
    value: number;
  } | null;
  milkAmount: {
    title: string;
    min: number;
    max: number;
    value: number;
  } | null;
  milkFoamAmount: {
    title: string;
    min: number;
    max: number;
    value: number;
  } | null;
  milkTemperature: {
    title: string;
    min: number;
    max: number;
    value: number;
  } | null;
  milkFoamTemperature: {
    title: string;
    min: number;
    max: number;
    value: number;
  } | null;
  milkBreak: {
    title: string;
    min: number;
    max: number;
    value: number;
  } | null;
  stroke: {
    title: string;
    min: number;
    max: number;
    value: number;
  } | null;
  temperature: {
    title: string;
    options: string[];
    index: number;
  } | null;
  templateService: ITemplateService;
}

function ProductDetail(props: ProductDetailProps) {
  const [t, i18n] = useTranslation("translation");
  const tt = (key: string) => props.templateService.t(key, i18n.language);
  return (
    <div className="p-4 overflow-y-scroll h-full flex flex-col gap-4 relative">
      <div>
        <div className="font-bold text-xl text-jura-brown-60">
          {tt(props.productTitleKey)}
        </div>
        <div className="w-6 h-1 bg-jura-brown-60"></div>
      </div>
      <div className="absolute top-3 right-3 cursor-pointer">
        <MaterialIcon icon={"close"} />
      </div>

      {props.coffeeStrength != null ? (
        <div className="border-b pb-3">
          <div className="font-bold text-sm mb-2">
            {tt(props.coffeeStrength.title)}
          </div>
          <CoffeeStrengthSlider
            total={props.coffeeStrength.levels}
            selected={props.coffeeStrength.index}
          />
        </div>
      ) : null}

      {props.waterAmount != null ? (
        <div className="border-b pb-3">
          <div className="font-bold text-sm mb-2">
            {tt(props.waterAmount.title)}
          </div>
          <RangeDisplay
            min={props.waterAmount.min}
            max={props.waterAmount.max}
            value={props.waterAmount.value}
          />
        </div>
      ) : null}
      {props.temperature != null ? (
        <div className="border-b pb-3">
          <div className="font-bold text-sm mb-2">
            {tt(props.temperature.title)}
          </div>
          <OptionsDisplay
            values={props.temperature.options.map((o) => tt(o))}
            selectedIndex={props.temperature.index}
          />
        </div>
      ) : null}
      {props.grinderRatio != null ? (
        <div className="border-b pb-3">
          <div className="font-bold text-sm mb-2">
            {tt(props.grinderRatio.title)}
          </div>
          <OptionsDisplay
            values={props.grinderRatio.options.map((o) => tt(o))}
            selectedIndex={props.grinderRatio.index}
          />
        </div>
      ) : null}
      {props.grinderFreeness != null ? (
        <div className="border-b pb-3">
          <div className="font-bold text-sm mb-2">
            {tt(props.grinderFreeness.title)}
          </div>
          <OptionsDisplay
            values={props.grinderFreeness.options.map((o) => tt(o))}
            selectedIndex={props.grinderFreeness.index}
          />
        </div>
      ) : null}
      {props.milkAmount != null ? (
        <div className="border-b pb-3">
          <div className="font-bold text-sm mb-2">
            {tt(props.milkAmount.title)}
          </div>
          <RangeDisplay
            min={props.milkAmount.min}
            max={props.milkAmount.max}
            value={props.milkAmount.value}
          />
        </div>
      ) : null}
      {props.milkTemperature != null ? (
        <div className="border-b pb-3">
          <div className="font-bold text-sm mb-2">
            {tt(props.milkTemperature.title)}
          </div>
          <RangeDisplay
            min={props.milkTemperature.min}
            max={props.milkTemperature.max}
            value={props.milkTemperature.value}
          />
        </div>
      ) : null}
      {props.milkFoamAmount != null ? (
        <div className="border-b pb-3">
          <div className="font-bold text-sm mb-2">
            {tt(props.milkFoamAmount.title)}
          </div>
          <RangeDisplay
            min={props.milkFoamAmount.min}
            max={props.milkFoamAmount.max}
            value={props.milkFoamAmount.value}
          />
        </div>
      ) : null}
      {props.milkFoamTemperature != null ? (
        <div className="border-b pb-3">
          <div className="font-bold text-sm mb-2">
            {tt(props.milkFoamTemperature.title)}
          </div>
          <RangeDisplay
            min={props.milkFoamTemperature.min}
            max={props.milkFoamTemperature.max}
            value={props.milkFoamTemperature.value}
          />
        </div>
      ) : null}
      {props.milkBreak != null ? (
        <div className="border-b pb-3">
          <div className="font-bold text-sm mb-2">
            {tt(props.milkBreak.title)}
          </div>
          <RangeDisplay
            min={props.milkBreak.min}
            max={props.milkBreak.max}
            value={props.milkBreak.value}
          />
        </div>
      ) : null}
      {props.stroke != null ? (
        <div className="border-b pb-3">
          <div className="font-bold text-sm mb-2">{tt(props.stroke.title)}</div>
          <RangeDisplay
            min={props.stroke.min}
            max={props.stroke.max}
            value={props.stroke.value}
          />
        </div>
      ) : null}
    </div>
  );
}

function MachineDataDashboardPage({
  readout,
  template,
  templateService,
}: {
  readout: CoffeeMachineReadout;
  template: CoffeeMachineTemplate;
  templateService: ITemplateService;
}) {
  const [t, i18n] = useTranslation("translation", { keyPrefix: "callerData" });

  return (
    <div className="h-full overflow-auto">
      <div className="">
        <DashboardDeviceInfo
          readout={readout}
          templateService={templateService}
        />
      </div>
      <div className="">
        <DashboardMaintenanceInfo
          readout={readout}
          template={template}
          templateService={templateService}
        />
      </div>
      <div className="">
        <div className="font-bold font-l">{t("Alarms")}</div>
        {readout.alerts.length == 0 ? (
          <div className="flex flex-row justify-center items-center text-jura-chrome-15">
            {t("No Alarms")}
          </div>
        ) : null}
        {readout.alerts.map((alert) => {
          var templateAlert = template.ALERTS.ALERT.find(
            (a) => a["@_Bit"] == alert.id
          );

          if (templateAlert == undefined) {
            return null;
          }

          return (
            <AlertListItem
              title={templateService.t(templateAlert["@_Title"], i18n.language)}
              text={templateService.t(
                templateAlert["@_Message"],
                i18n.language
              )}
              key={alert.id}
            />
          );
        })}
      </div>
    </div>
  );
}

function DashboardDeviceInfo({
  readout,
  templateService,
}: {
  readout: CoffeeMachineReadout;
  templateService: ITemplateService;
}) {
  const [t, i18n] = useTranslation("translation", { keyPrefix: "callerData" });
  return (
    <div className="flex flex-row gap-2">
      <div className=" h-20 w-20">
        <img
          src={makeCoffeeMachineImageUrl(
            readout.articleNumber,
            CoffeeMachineImageResolution.res512x512
          )}
          className="h-full object-cover mx-auto"
        />
      </div>
      <div
        className="flex-1 grid text-sm"
        style={{ gridTemplateColumns: "min-content auto", columnGap: "2rem" }}
      >
        <div className="font-bold ">{t("Model")}</div>
        <div>{readout.modelName}</div>
        <div className="font-bold">{t("Article Number")}</div>
        <div>{readout.articleNumber}</div>
        <div className="font-bold">{t("Unique Id")}</div>
        <div>{readout.uniqueId}</div>
        <div className="font-bold">{t("Communication Type")}</div>
        <div>
          {readout.connectionType == "bluetooth"
            ? t("Bluetooth")
            : readout.connectionType == "wifi"
            ? t("Wifi")
            : "MOCK"}
        </div>
        <div className="font-bold">{t("Connect Type")}</div>
        <div>
          {readout.frogType == "smartConnect"
            ? "SmartConnect"
            : readout.frogType == "wifiConnect"
            ? "WifiConnect"
            : "MOCK"}
        </div>
      </div>
    </div>
  );
}

function DashboardMaintenanceInfo({
  readout,
  template,
  templateService,
}: {
  readout: CoffeeMachineReadout;
  template: CoffeeMachineTemplate;
  templateService: ITemplateService;
}) {
  const [t, i18n] = useTranslation("translation", { keyPrefix: "callerData" });

  var maintenanceCounterTemplate = template.STATISTIC.MAINTENANCEPAGE.BANK.find(
    (b) => b["@_Command"] == "@TG:43"
  );
  var maintenancePercentTemplate = template.STATISTIC.MAINTENANCEPAGE.BANK.find(
    (b) => b["@_Command"] == "@TG:C0"
  );

  return (
    <div className="flex flex-col gap-4 py-5">
      <div className="flex flex-col">
        <div className="font-bold text-l">{t("Cycles")}</div>
        {readout.maintenanceCounter.map((counter) => {
          var templateCounter = maintenanceCounterTemplate?.TEXTITEM?.find(
            (i) => i["@_Type"] == counter.id
          );
          if (templateCounter == undefined) {
            return null;
          }
          return (
            <MaintenanceCounterItem
              title={templateService.t(
                templateCounter!["@_Text"],
                i18n.language
              )}
              count={counter.count}
            />
          );
        })}
      </div>
      <div className="flex flex-col gap-2">
        <div className="font-bold text-l">{t("Progress")}</div>
        {readout.maintenanceStatus.map((percent) => {
          var templatePercent = maintenancePercentTemplate?.TEXTITEM?.find(
            (i) => i["@_Type"] == percent.id
          );
          if (templatePercent == undefined) {
            return null;
          }
          return (
            <MaintenancePercentItem
              title={templateService.t(
                templatePercent!["@_Text"],
                i18n.language
              )}
              percent={percent.percent}
            />
          );
        })}
      </div>
    </div>
  );
}

function MaintenancePercentItem({
  title,
  percent,
}: {
  title: string;
  percent: number;
}) {
  return (
    <div className="p-2 flex flex-row justify-between border-b">
      <div className="text-sm">{title}</div>
      <div className="text-sm">{percent}%</div>
    </div>
  );
}

function MaintenanceCounterItem({
  title,
  count,
}: {
  title: string;
  count: number;
}) {
  return (
    <div className="p-2 flex flex-row justify-between border-b">
      <div className="text-sm">{title}</div>
      <div className="text-sm">{count}</div>
    </div>
  );
}

function AlertListItem({ title, text }: { title: string; text: string }) {
  return (
    <div className="flex flex-row items-center border-b py-2 gap-2">
      <MaterialIcon icon="warning" />
      <div className="flex flex-col ">
        <div className=" text-sm font-bold">{title}</div>
        <div className=" text-xs">{text}</div>
      </div>
    </div>
  );
}

/**
 * This is the data browser for the joe data
 * @param param0
 * @returns
 */
function JoeDataBrowser({
  readout,
  template,
  templateService,
  onClose,
}: {
  readout: JoeReadout;
  template: CoffeeMachineTemplate | null;
  templateService: ITemplateService;
  onClose: () => void;
}) {
  const [t, i18n] = useTranslation("translation", { keyPrefix: "callerData" });
  return (
    <DataBrowser
      pages={[
        {
          title: t("Products"),
          content:
            template != null ? (
              <JoeDataProductPage
                readout={readout}
                template={template}
                templateService={templateService}
              />
            ) : (
              <div>no product info</div>
            ),
        },
        {
          title: t("Settings"),
          content: (
            <JoeDataSettingsPage
              readout={readout}
              template={template}
              templateService={templateService}
            />
          ),
        },
        {
          title: t("Device"),
          content: (
            <JoeDevicePage
              readout={readout}
              template={template}
              templateService={templateService}
            />
          ),
        },
      ]}
      header={<UpdatedOn date={readout.timestamp} />}
      onClose={onClose}
    />
  );
}

function JoeDataProductPage({
  readout,
  template,
  templateService,
}: {
  readout: JoeReadout;
  template: CoffeeMachineTemplate;
  templateService: ITemplateService;
}) {
  const [t, i18n] = useTranslation("translation");

  var sortedProducts = readout.products;

  const getProductDetails = (
    productId: string,
    readout: JoeReadout,
    template: CoffeeMachineTemplate
  ) => {
    var product = readout.products.find((product) => product.id == productId);
    var templateProduct = template.PRODUCTS.PRODUCT.find(
      (product) => product["@_Code"] == productId
    );

    if (product == undefined || templateProduct == undefined) {
      return null;
    }

    var props: ProductDetailProps = {
      productTitleKey: templateProduct["@_Text"],
      grinderRatio: null,
      grinderFreeness: null,
      coffeeStrength: null,
      waterAmount: null,
      temperature: null,
      milkAmount: null,
      milkTemperature: null,
      milkFoamAmount: null,
      milkFoamTemperature: null,
      milkBreak: null,
      stroke: null,
      templateService: templateService,
    };

    // Grinder Ratio
    var templateGrinderRatio = templateProduct.GRINDER_RATIO;
    var grinderRatioValue =
      product.parameters.find(
        (p) => p.id == templateProduct!.GRINDER_RATIO?.["@_Argument"]
      )?.value ?? null;
    if (templateGrinderRatio != null && grinderRatioValue != null) {
      props.grinderRatio = {
        title: templateGrinderRatio["@_Text"],
        index: templateGrinderRatio.ITEM.findIndex(
          (item) => item["@_Value"] == grinderRatioValue
        ),
        options: templateGrinderRatio.ITEM.map((item) => item["@_Text"]),
      };
    }

    // Grinder Freeness
    var templateGrinderFreeness = templateProduct.GRINDER_FREENESS;
    var grinderFreenessValue =
      product.parameters.find(
        (p) => p.id == templateProduct!.GRINDER_FREENESS?.["@_Argument"]
      )?.value ?? null;

    if (templateGrinderFreeness != null && grinderFreenessValue != null) {
      props.grinderFreeness = {
        title: templateGrinderFreeness["@_Text"],
        index: templateGrinderFreeness.ITEM.findIndex(
          (item) => item["@_Value"] == grinderFreenessValue
        ),
        options: templateGrinderFreeness.ITEM.map((item) => item["@_Text"]),
      };
    }

    // Coffee Strength
    var templateCoffeeStrength = templateProduct.COFFEE_STRENGTH;
    var coffeeStrengthValue =
      product.parameters.find(
        (p) => p.id == templateProduct!.COFFEE_STRENGTH?.["@_Argument"]
      )?.value ?? null;

    if (coffeeStrengthValue != null && templateCoffeeStrength != null) {
      props.coffeeStrength = {
        title: templateCoffeeStrength["@_Text"],
        index: templateCoffeeStrength.ITEM.findIndex(
          (item) => item["@_Value"] == coffeeStrengthValue
        ),
        levels: templateCoffeeStrength.ITEM.length,
      };
    }

    // Water Amount
    var templateWaterAmount = templateProduct.WATER_AMOUNT;
    var waterAmountValue =
      product.parameters.find(
        (p) => p.id == templateProduct!.WATER_AMOUNT?.["@_Argument"]
      )?.value ?? null;
    if (templateWaterAmount != null && waterAmountValue != null) {
      props.waterAmount = {
        title: templateWaterAmount["@_Text"],
        min: parseInt(templateWaterAmount["@_Min"]),
        max: parseInt(templateWaterAmount["@_Max"]),
        value: parseInt(waterAmountValue, 16),
      };
    }

    // Temperature
    var templateTemperature = templateProduct.TEMPERATURE;
    var temperatureValue =
      product.parameters.find(
        (p) => p.id == templateProduct!.TEMPERATURE?.["@_Argument"]
      )?.value ?? null;

    if (templateTemperature != null && temperatureValue != null) {
      props.temperature = {
        title: templateTemperature["@_Text"],
        index: templateTemperature.ITEM.findIndex(
          (item) => item["@_Value"] == temperatureValue
        ),
        options: templateTemperature.ITEM.map((item) => item["@_Text"]),
      };
    }

    // Milk Amount
    var templateMilkAmount = templateProduct.MILK_AMOUNT;
    var milkAmountValue =
      product.parameters.find(
        (p) => p.id == templateProduct!.MILK_AMOUNT?.["@_Argument"]
      )?.value ?? null;
    if (templateMilkAmount != null && milkAmountValue != null) {
      props.milkAmount = {
        title: templateMilkAmount["@_Text"],
        min: parseInt(templateMilkAmount["@_Min"]),
        max: parseInt(templateMilkAmount["@_Max"]),
        value: parseInt(milkAmountValue, 16),
      };
    }

    //Milk temperature
    var templateMilkTemperature = templateProduct.MILK_TEMP;
    var milkTemperatureValue =
      product.parameters.find(
        (p) => p.id == templateProduct!.MILK_TEMP?.["@_Argument"]
      )?.value ?? null;

    if (templateMilkTemperature != null && milkTemperatureValue != null) {
      props.milkTemperature = {
        title: templateMilkTemperature["@_Text"],
        min: parseInt(templateMilkTemperature["@_Min"]),
        max: parseInt(templateMilkTemperature["@_Max"]),
        value: parseInt(milkTemperatureValue, 16),
      };
    }

    //Milk foam amount
    var templateMilkFoamAmount = templateProduct.MILK_FOAM_AMOUNT;
    var milkFoamAmountValue =
      product.parameters.find(
        (p) => p.id == templateProduct!.MILK_FOAM_AMOUNT?.["@_Argument"]
      )?.value ?? null;
    if (templateMilkFoamAmount != null && milkFoamAmountValue != null) {
      props.milkFoamAmount = {
        title: templateMilkFoamAmount["@_Text"],
        min: parseInt(templateMilkFoamAmount["@_Min"]),
        max: parseInt(templateMilkFoamAmount["@_Max"]),
        value: parseInt(milkFoamAmountValue, 16),
      };
    }

    //Milk Foam Temperature
    var templateMilkFoamTemperature = templateProduct.MILK_FOAM_TEMP;
    var milkFoamTemperatureValue =
      product.parameters.find(
        (p) => p.id == templateProduct!.MILK_FOAM_TEMP?.["@_Argument"]
      )?.value ?? null;
    if (
      templateMilkFoamTemperature != null &&
      milkFoamTemperatureValue != null
    ) {
      props.milkFoamTemperature = {
        title: templateMilkFoamTemperature["@_Text"],
        min: parseInt(templateMilkFoamTemperature["@_Min"]),
        max: parseInt(templateMilkFoamTemperature["@_Max"]),
        value: parseInt(milkFoamTemperatureValue, 16),
      };
    }

    //Milk Break
    var templateMilkBreak = templateProduct.MILK_BREAK;
    var milkBreakValue =
      product.parameters.find(
        (p) => p.id == templateProduct!.MILK_BREAK?.["@_Argument"]
      )?.value ?? null;
    if (templateMilkBreak != null && milkBreakValue != null) {
      props.milkBreak = {
        title: templateMilkBreak["@_Text"],
        min: parseInt(templateMilkBreak["@_Min"]),
        max: parseInt(templateMilkBreak["@_Max"]),
        value: parseInt(milkBreakValue, 16),
      };
    }

    //Stroke
    var templateStrokeValue = templateProduct.STROKE;
    var strokeValue =
      product.parameters.find(
        (p) => p.id == templateProduct!.STROKE?.["@_Argument"]
      )?.value ?? null;
    if (templateStrokeValue != null && strokeValue != null) {
      props.stroke = {
        title: templateStrokeValue["@_Text"],
        min: parseInt(templateStrokeValue["@_Min"]),
        max: parseInt(templateStrokeValue["@_Max"]),
        value: parseInt(strokeValue, 16),
      };
    }

    return props;
  };

  const [productDetails, setProductDetails] =
    useState<ProductDetailProps | null>(null);

  return (
    <div
      className={[
        "h-full w-full relative",
        productDetails == null ? " overflow-y-scroll" : "overflow-y-hidden",
      ].join("")}
    >
      {productDetails != null ? (
        <div
          className="absolute w-full h-full bg-black bg-opacity-40 p-8"
          onClick={() => setProductDetails(null)}
        >
          <div className="bg-white w-full h-full">
            <ProductDetail {...productDetails} />
          </div>
        </div>
      ) : null}

      {sortedProducts.map((product) => {
        var templateProduct = template.PRODUCTS.PRODUCT.find(
          (p) => p["@_Code"] == product.id
        );

        if (templateProduct == undefined) {
          return null;
        }

        return (
          <ProductListItem
            name={templateService.t(templateProduct["@_Text"], i18n.language)}
            customName={product.customName}
            consumptions={null}
            preselections={product.preselections}
            img={"/assets/images/" + templateProduct["@_PictureIdle"]}
            onClick={() => {
              setProductDetails(
                getProductDetails(product.id, readout, template)
              );
            }}
            key={product.id}
          />
        );
      })}
    </div>
  );
}

function JoeDevicePage({
  readout,
  template,
  templateService,
}: {
  readout: JoeReadout;
  template: CoffeeMachineTemplate | null;
  templateService: ITemplateService;
}) {
  const [t, i18n] = useTranslation("translation", { keyPrefix: "callerData" });

  return (
    <div className="w-full h-full overflow-y-auto px-2 pt-6">
      {/* Device data  */}
      <ListItemTitle title={t("Device")} />
      <ListItem
        title={t("Connectivity")}
        value={
          <div className="flex flex-row gap-2 justify-end">
            {readout.bleEnabled ? (
              <MaterialIcon icon="bluetooth" />
            ) : (
              <MaterialIcon icon="bluetooth_disabled" />
            )}
            {readout.wifiEnabled ? (
              <MaterialIcon icon="wifi" />
            ) : (
              <MaterialIcon icon="wifi_off" />
            )}
          </div>
        }
      />
      <ListItem title={t("Operating System")} value={readout.osVersion} />
      <ListItem title={t("Model")} value={readout.deviceModel} />
      <ListItem title={t("Language")} value={readout.deviceLanguage} />
      <div className="mb-10"></div>
      {/* Permissions */}
      <ListItemTitle title={t("Permissions")} />
      {readout.permissions.map((p) => (
        <ListItem
          title={p.name}
          value={
            p.granted ? (
              <MaterialIcon icon="check" style={{ color: "green" }} />
            ) : (
              <MaterialIcon icon="close" style={{ color: "red" }} />
            )
          }
        />
      ))}
    </div>
  );
}

function ListItemTitle({ title }: { title: string }) {
  return <div className="font-bold text-l border-b">{title}</div>;
}

function ListItem({
  title,
  value,
}: {
  title: React.ReactNode;
  value: React.ReactNode;
}) {
  return (
    <div className="border-b flex flex-row justify-between py-4">
      <div className="flex-1 break-all text-left text-sm">{title}</div>
      <div className="flex-1 break-words text-right text-sm">{value}</div>
    </div>
  );
}

function JoeDataSettingsPage({
  readout,
  template,
  templateService,
}: {
  readout: JoeReadout;
  template: CoffeeMachineTemplate | null;
  templateService: ITemplateService;
}) {
  const [t, i18n] = useTranslation("translation", { keyPrefix: "callerData" });
  return (
    <div className="px-2 pt-6">
      <ListItemTitle title={t("Settings")} />
      {readout.settings.map((s) => (
        <JoeSettingListItem setting={s} />
      ))}
    </div>
  );
}

function JoeSettingListItem({ setting }: { setting: JoeReadout.Setting }) {
  return (
    <div className="border-b flex flex-row justify-between py-4">
      <div>{setting.id}</div>
      <div>{setting.value}</div>
    </div>
  );
}
export interface DataBrowserProps {
  pages: {
    title: string;
    content: JSX.Element;
  }[];
  header?: JSX.Element;
  onClose: () => void;
}

/**
 * This is a tab control which hosts some data, split into tabs
 * @param param0
 * @returns
 */
function DataBrowser({ pages, header = undefined, onClose }: DataBrowserProps) {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  let safeIndex = currentIndex;
  safeIndex = safeIndex > pages.length - 1 ? pages.length - 1 : safeIndex;
  safeIndex = safeIndex < 0 ? 0 : safeIndex;

  return (
    <div className="bg-white flex flex-col h-full justify-stretch">
      {/* Tabs */}
      <div className=" h-min border-b flex flex-row items-center gap-6 px-2 py-2">
        <div className="flex-1 flex flex-row gap-3 justify-start items-center text-sm flex-wrap">
          {pages.map((page, index) => (
            <div
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={[
                "transition cursor-pointer hover:font-bold grid grid-cols-1",
                safeIndex == index ? "font-bold" : "",
              ].join(" ")}
            >
              {/* Show text twice on top of each other, once always bold and transparent. This prevents resize on hover */}
              <div
                className=" m-auto"
                style={{ gridRowStart: 1, gridColumnStart: 1 }}
              >
                {page.title}
              </div>
              <div
                className="font-bold text-transparent"
                style={{ gridRowStart: 1, gridColumnStart: 1 }}
              >
                {page.title}
              </div>
            </div>
          ))}
        </div>
        <div
          className="cursor-pointer h-min flex flex-row items-center transition hover:scale-105"
          onClick={onClose}
        >
          <MaterialIcon icon="close" />
        </div>
      </div>
      {/* Header */}
      <div className="h-auto">{header}</div>
      {/* Content */}
      <div className="flex-1 relative">
        <div className="h-full w-full overflow-hidden absolute p-2">
          {pages[safeIndex].content}
        </div>
      </div>
    </div>
  );
}

export interface DataGridProps {
  items: JSX.Element[];
}

/**
 * This is the grid which shows the available data sets (Joe, CoffeeMachine etc.)
 * @param param0
 * @returns
 */
function DataGrid({ items }: DataGridProps) {
  return items.length == 0 ? (
    <div className="flex flex-row items-center justify-center h-full w-full bg-jura-chrome-15">
      No Data Available
    </div>
  ) : (
    <div className="grid grid-cols-2 gap-2 w-full">
      {items.map((item) => item)}
    </div>
  );
}

export interface DataGridItemProps {
  content: JSX.Element;
  onClick: () => void;
}

function DataGridItem({ content, onClick }: DataGridItemProps) {
  return (
    <div
      className="h-40 cursor-pointer border-jura-chrome-15 bg-white overflow-hidden hover:brightness-95 transition"
      onClick={onClick}
    >
      {content}
    </div>
  );
}

interface JoeDataGridItemProps {
  readout: JoeReadout;
  onClick: () => void;
}

function JoeDataGridItem({ readout, onClick }: JoeDataGridItemProps) {
  return (
    <DataGridItem
      onClick={onClick}
      content={
        <div className="flex flex-col h-full items-center p-2">
          <div className="w-full flex-1 relative">
            <div className="w-full h-full max-h-full absolute">
              <img src={joeGridIcon} className="h-full object-cover mx-auto" />
            </div>
          </div>
          <div className="w-full p-2">
            <div className="border-b border-jura-chrome-15 w-full"></div>
          </div>
          <div className="h-5 font-bold">J.O.E.</div>
        </div>
      }
    />
  );
}

interface CoffeeMachineDataGridItemProps {
  readout: CoffeeMachineReadout;
  onClick: () => void;
}

function CoffeeMachineDataGridItem({
  readout,
  onClick,
}: CoffeeMachineDataGridItemProps) {
  return (
    <DataGridItem
      onClick={onClick}
      content={
        <div className="flex flex-col h-full items-center p-2">
          <div className="w-full flex-1 relative">
            <div className="w-full h-full max-h-full absolute">
              <img
                src={makeCoffeeMachineImageUrl(
                  readout.articleNumber,
                  CoffeeMachineImageResolution.res512x512
                )}
                className="h-full object-cover mx-auto"
              />
            </div>
          </div>
          <div className="w-full p-2">
            <div className="border-b border-jura-chrome-15 w-full"></div>
          </div>
          <div className="h-5 font-bold">{readout.modelName}</div>
        </div>
      }
    />
  );
}

function UpdatedOn({ date }: { date: Date }) {
  const [t, i18n] = useTranslation("translation", { keyPrefix: "callerData" });

  const renderContent = () => {
    var now = new Date(Date.now());
    var elapsedMinutes = (now.getTime() - date.getTime()) / 1000 / 60;

    if (elapsedMinutes < 2) {
      return <div>{t("Just now")}</div>;
    } else if (elapsedMinutes < 10) {
      return <div>{t("A few minutes ago")}</div>;
    } else {
      return (
        <div className=" text-red-800 font-bold">{date.toLocaleString()}</div>
      );
    }
  };
  return (
    <div className="flex flex-row gap-1 text-xs text-jura-chrome-60 px-2 py-2">
      <div className="">{t("Updated on")}:</div>
      <div>{renderContent()}</div>
    </div>
  );
}
export default Call;
