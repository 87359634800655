import axios from "axios";
import { X2jOptions, XMLParser } from "fast-xml-parser";
import { CoffeeMachineTemplate } from "./CoffeeMachineTemplate";
import { CoffeeMachineTemplateTextProvider } from "./CoffeeMachineTemplateTexts";

export async function init(): Promise<CoffeeMachineTemplates> {
  let coffeeMachineTemplates: CoffeeMachineTemplate[] = [];
  let coffeeMachineTemplateTextProvider: CoffeeMachineTemplateTextProvider | null =
    null;

  //load templates
  /*
  await axios
    .get("/assets/coffeeMachineTemplates/machines.txt")
    .then(async (response) => {
      var data = response.data as string;
      var lines = data.split(/\r?\n/);
      lines.shift();

      var efMap = new Map<string, string>();
      for (var line of lines) {
        try {
          let infos = line.split(";");
          //skip if crap data
          if (infos.length != 4) {
            continue;
          }
          let ef = infos[2].toUpperCase();
          if (line.search("1092") != -1) {
            var adfasddf = "";
          }
          //skip if crap data
          if (ef == undefined) {
            continue;
          }
          //already in map
          if (efMap.has(ef)) {
            continue;
          }

          var xmlData = await axios.get(
            `/assets/coffeeMachineTemplates/${ef}.xml`
          );

          //make sure the xml has some data
          if (xmlData?.data == null || xmlData?.data == undefined) {
            continue;
          }

          efMap.set(ef, xmlData.data);
        } catch (e) {
          //if anything fails try with the next line
          console.log("failed to get data for: " + line);
        }
      }
      for (let ef of Array.from(efMap.keys())) {
        var xml = efMap.get(ef);
        if (xml == undefined) {
          continue;
        }

        var xmlParser = new XMLParser({
          ignoreAttributes: false,
          isArray: (tagName, jPath, isLeafNode, isAttribute) => {
            if (tagName == "PRODUCT") return true;
            if (tagName == "LINK") return true;
            if (tagName === "PROCESS") return true;
            if (tagName === "COMBOBOX") return true;
            if (tagName === "SLIDER") return true;
            if (tagName === "SWITCH") return true;
            if (tagName === "BANK") return true;
            if (tagName === "TEXTITEM") return true;
            return false;
          },
        });
        var parsed = xmlParser.parse(xml);

        //Make sure data is valid!
        if (parsed?.JOE == null || parsed?.JOE == undefined) {
          continue;
        }
        coffeeMachineTemplates.push(parsed["JOE"] as CoffeeMachineTemplate);
        console.log(ef);
      }
    });
  */

  //load texts
  await axios
    .get("assets/coffeeMachineTemplates/texts.ini")
    .then((response) => {
      var data = response.data as string;
      var lines = data.split(/\r?\n/);

      let localizedTextMaps = new Map<string, Map<string, string>>();
      let currentLocale = "";

      for (var i = 0; i < lines.length; i++) {
        var line = lines[i];

        if (line.includes("=")) {
          let parts = line.split("=");
          var key = parts[0];
          var value = parts[1];
          var foo = localizedTextMaps.get(currentLocale);
          foo?.set(key, value);
        } else {
          currentLocale = line.substring(
            line.indexOf("[") + 1,
            line.indexOf("]")
          );
          localizedTextMaps.set(currentLocale, new Map<string, string>());
        }
      }

      coffeeMachineTemplateTextProvider = new CoffeeMachineTemplateTextProvider(
        localizedTextMaps
      );
    });

  return {
    textProvider: coffeeMachineTemplateTextProvider!,
    templates: coffeeMachineTemplates,
  };
}

export interface CoffeeMachineTemplates {
  textProvider: CoffeeMachineTemplateTextProvider;
  templates: CoffeeMachineTemplate[];
}

export class CoffeeMachineTemplateLoader {
  async load(): Promise<CoffeeMachineTemplates> {
    return init();
  }
}
