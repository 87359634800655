import JuraLogo from "../../components/JuraLogo/JuraLogo";

interface Props {
  percent: number;
}
export function LoadingPage({ percent }: Props): JSX.Element {
  console.log("percent" + percent);
  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center">
      <div className="w-40">
        <JuraLogo />
      </div>
      <div className="mt-4">
        <div className="text-xl font-bold">Digital Customer Support</div>

        <div className="h-2 bg-jura-brown-15 mt-20 rounded-full overflow-hidden">
          <div
            className="h-full bg-jura-brown-60 transition-all"
            style={{ width: `${percent}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default LoadingPage;
