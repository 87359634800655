import css from "./RangeDisplay.module.css";

interface RangeDisplayProps {
  min: number;
  max: number;
  value: number;
}
function RangeDisplay(props: RangeDisplayProps): JSX.Element {
  let value = props.value;

  //limit range of value
  if (value > props.max) {
    value = props.max;
  }
  if (value < props.min) {
    value = props.min;
  }

  var progress = (value - props.min) / (props.max - props.min);
  return (
    <div className="">
      {/* The top texts */}
      <div className=" flex flex-row justify-between relative">
        <div className=" text-transparent">{props.value}</div>
        {/* Value text */}
        <div className={`h-full absolute flex flex-row items-center w-full `}>
          <div style={{ flex: progress * 100 }}></div>
          <div className="font-bold text-sm">{value}</div>
          <div style={{ flex: (1 - progress) * 100 }}></div>
        </div>
      </div>
      <div className="flex flex-row relative isolate justify-between items-center">
        {/* The track */}
        <div className="h-full absolute left-2 right-2 z-[-1] flex flex-col justify-center">
          <div className=" h-1 bg-jura-chrome-15"></div>
        </div>
        {/* The value knob */}
        <div className={`h-full absolute flex flex-row items-center w-full `}>
          <div style={{ flex: progress * 100 }}></div>
          <div className="h-4 w-4 bg-jura-brown-60 rounded-full "></div>
          <div style={{ flex: (1 - progress) * 100 }}></div>
        </div>
        {/* The start and end knobs */}
        <div className="h-4 w-4 bg-jura-chrome-15 rounded-full"></div>
        <div className="h-4 w-4 bg-jura-chrome-15 rounded-full"></div>
      </div>
      {/* The bottom texts */}
      <div className=" flex flex-row justify-between relative">
        {/* Min text */}
        <div className="">
          <div className="flex flex-row justify-start">
            <div className="break-words inline-block max-w-full text-start text-sm">
              {props.min}
            </div>
          </div>
        </div>

        {/* Max text */}
        <div className="">
          <div className="flex flex-row justify-start">
            <div className="break-words inline-block max-w-full text-end text-sm">
              {props.max}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RangeDisplay;
