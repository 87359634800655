class SightcallConsole {
  private sightCall: any = undefined;
  private verbose: boolean;

  iframeId: string;

  constructor(iframeId: string, verbose: boolean = false) {
    this.iframeId = iframeId;
    this.verbose = verbose;

    //Create new instance of SightcallConsole class in iframe mode
    this.sightCall = new (window as any).SightcallConsole({
      mode: "iframe",
      target: this.iframeId,
    });

    this.log("initialised");
  }

  /**
   * Method must be called before using any sightcall console object
   */
  static async init(): Promise<void> {
    //Checks if SightcallConsole class is already available
    if ((window as Object).hasOwnProperty("SightcallConsole") == false) {
      //Create new script element
      var script = document.createElement("script");

      //Create new promise that waits for a script to load
      var promise = new Promise<void>((resolve, reject) => {
        script.addEventListener("load", () => {
          resolve();
        });
      });

      //Add the script element to the document
      script.type = "text/javascript";
      script.src = "https://console.sightcall.com/sightcall_console.js";
      document.body.appendChild(script);

      //Await the script loading
      await promise;
    }
  }

  /**
   * Helper method for logging to console
   * @param text
   */
  private log(text: string): void {
    if (this.verbose) {
      console.log("SightcallConsole::" + text);
    }
  }

  /**
   * Checks if the sightcal library has been loaded and the internal sightcall object has been created
   */
  private checkIfInitialised() {
    if (this.sightCall == undefined) {
      throw new Error("SightcallConsole must be initialised first");
    }
  }

  /**
   * Opens the sightcall connection and displays it in the target iframe
   */
  open(): void {
    this.checkIfInitialised();

    this.sightCall.open();
  }

  /**
   * Closes the sightcall connection
   */
  async close(): Promise<void> {
    this.checkIfInitialised();

    if (this.isOpen() == false) {
      return;
    }

    var promise = new Promise<void>(async (resolve) => {
      var callbackHandle = this.onConsoleClosed(() => {
        callbackHandle.cancel();
        resolve();
      });
    });

    this.sightCall.close();
    await promise;
  }

  /**
   * Attempts to authenticate against a SSO method
   * @param url
   */
  ssoConnect(ssoToken: string): void {
    this.checkIfInitialised();

    this.sightCall.connect({
      auth_mode: "internal",
      temporary_token: ssoToken,
    });
  }

  /**
   * Disconnects the agent from Sightcall
   */
  async disconnect(): Promise<void> {
    this.checkIfInitialised();
    console.log("DISCONNECT CALLED");
    var sub1: SightcallEventSubscription | null;
    var promise = new Promise<void>(async (resolve) => {
      var sub2 = this.onConsoleRequireAuth(() => {
        sub2?.cancel();
        resolve();
      });
    });

    this.sightCall.disconnect();

    await Promise.race([promise, new Promise((r) => setTimeout(r, 2000))]);
  }

  /**
   * Activates or deactivates the agenst microphone
   * @param state
   */
  setLocalMicrophone(state: MicrophoneState) {
    this.checkIfInitialised();

    this.sightCall.buttonAction(
      "localSound",
      state == MicrophoneState.on ? "on" : "off"
    );
  }

  /**
   * Activates or deactivates the remote microphone
   * @param state
   */
  setRemoteMicrophone(state: MicrophoneState) {
    this.checkIfInitialised();

    this.sightCall.buttonAction(
      "remoteSound",
      state == MicrophoneState.on ? "on" : "off"
    );
  }
  /**
   * Activates or deactivates the remote microphone
   * @param state
   */
  setRemoteCamera(state: CameraState) {
    this.checkIfInitialised();

    this.sightCall.buttonAction(
      "remoteVideo",
      state == CameraState.on ? "on" : "off"
    );
  }
  /**
   * Activates or deactivates the agents Camera
   * @param state
   */
  setLocalVideo(state: CameraState): void {
    this.checkIfInitialised();

    this.sightCall.buttonAction(
      "localVideo",
      state == CameraState.on ? "on" : "off"
    );
  }

  /**
   * Pauses or resumes the guest's video feed
   */
  setRemotePause(state: PlaybackState): void {
    this.checkIfInitialised();
    this.sightCall.buttonAction(
      "remotePause",
      state == PlaybackState.pause ? "on" : "off"
    );
  }
  /**
   * Accepts an ACD Request
   */
  acceptAcdRequest(): void {
    this.checkIfInitialised();
    this.sightCall.acceptRequest();
  }

  /**
   * Declines an ACD Request
   */
  declineAcdRequest(): void {
    this.checkIfInitialised();
    this.sightCall.declineRequest();
  }

  /**
   * Checks if the Sightcall Console is open
   * @returns
   */
  isOpen(): boolean {
    this.checkIfInitialised();
    return this.sightCall.isOpen();
  }

  /**
   * Hangs up the call
   * @param deleteCode if the code should be deleted or not
   */
  hangUp(deleteCode: boolean): void {
    this.checkIfInitialised();
    this.sightCall.hangup(deleteCode);
  }

  /**
   * Saves an image of the guest's video feed
   */
  remoteSaveImage(): void {
    this.checkIfInitialised();
    this.sightCall.buttonAction("remoteSave");
  }

  /**
   * Requests the guest location, which will be delivered by event
   */
  requestGuestLocation(): void {
    this.checkIfInitialised();
    this.sightCall.geolocationRequest();
    console.log("guest location requested");
  }

  /**
   * Gets the current duration of the call in milliseconds
   * @returns
   */
  getTimestampInMilliseconds(): number {
    this.checkIfInitialised();
    return this.sightCall.recordBookmark();
  }

  /**
   * Activates the remote light
   */
  setRemoteLight(state: LightState): void {
    this.checkIfInitialised();
    this.sightCall.buttonAction(
      "remoteLight",
      state == LightState.on ? "on" : "off"
    );
  }

  testButtonAction(action: string) {
    this.checkIfInitialised();
    this.sightCall.buttonAction(action);
  }

  /**
   * Registers a callback on an event
   * @param event the name of the event to listen for
   * @param callback the callback
   */
  private registerCallback(
    event: string,
    callback: () => void
  ): SightcallEventSubscription {
    // Check if the sightcall console has been created
    this.checkIfInitialised();
    // Create a callback which adds logging
    var wrappedCallback = (args: any) => {
      this.log(`callback on event: ${event} args: ${JSON.stringify(args)}`);
      callback();
    };
    // Attach the callback to the event
    this.sightCall.on(event, wrappedCallback);
    // Return an event subscription which can be used to cancel
    return new SightcallEventSubscription(event, wrappedCallback, this);
  }

  /**
   * Registers a callback on an event
   * @param event the name of the event to listen for
   * @param callback the callback
   * @param mapArgs maps the passed parameters to a typed object
   */
  private registerCallbackWith<T>(
    event: string,
    callback: (args: T) => void,
    mapArgs: (args: any) => T
  ): SightcallEventSubscription {
    // Check if the sightcall console has been created
    this.checkIfInitialised();
    // Create a callback which translates the passed parameters to a typed object and adds logging
    var wrappedCallback = (input: any) => {
      var args = mapArgs(input);
      this.log(`callback on event: ${event} args: ${JSON.stringify(args)}`);
      callback(args);
    };
    // Attach the callback to the event
    this.sightCall.on(event, wrappedCallback);
    // Return an event subscription which can be used to cancel
    return new SightcallEventSubscription(event, wrappedCallback, this);
  }

  /**
   * Registers a callback to be executed when the guests location is received
   * @param callback the callback to execute
   */
  onGuestLocationReceived(
    callback: (args: OnGuestLocationReceivedArgs) => void
  ): SightcallEventSubscription {
    return this.registerCallbackWith<OnGuestLocationReceivedArgs>(
      "geolocation.value",
      callback,
      (...args) => {
        return {
          location: {
            longitude: args[0].longitude,
            latitude: args[0].latitude,
          },
        };
      }
    );
  }
  /**
   * Registers a callback to be executed when the console is opened
   * @param callback the callback to execute
   */
  onConsoleOpened(callback: () => void): SightcallEventSubscription {
    return this.registerCallback("console.opened", callback);
  }
  /**
   * Registers a callback to be executed when the console is closed
   * @param callback the callback to execute
   */
  onConsoleClosed(callback: () => void): SightcallEventSubscription {
    return this.registerCallback("console.closed", callback);
  }
  /**
   * Registers a callback to be executed when the console requires authentication
   * @param callback the callback to execute
   */
  onConsoleRequireAuth(callback: () => void): SightcallEventSubscription {
    return this.registerCallback("console.requireAuth", callback);
  }
  /**
   * Registers a callback to be executed when the console is resized
   * @param callback the callback to execute
   */
  onConsoleResized(
    callback: (args: OnConsoleResizedArgs) => void
  ): SightcallEventSubscription {
    return this.registerCallbackWith<OnConsoleResizedArgs>(
      "console.resized",
      callback,
      (...args) => {
        return {
          width: args[0].width,
          height: args[0].height,
        };
      }
    );
  }
  /**
   * Registers a callback to be executed when an auth error has occured
   * @param callback the callback to execute
   */
  onAuthError(callback: () => void): SightcallEventSubscription {
    return this.registerCallback("auth.error", callback);
  }
  /**
   * Registers a callback to be executed when auth has been successful
   * @param callback the callback to execute
   */
  onAuthSuccess(callback: () => void): SightcallEventSubscription {
    return this.registerCallback("auth.success", callback);
  }
  /**
   * Registers a callback to be executed when the extension is missing
   * @param callback the callback to execute
   */
  onExtensionMissing(
    callback: (args: OnExtensionMissingArgs) => void
  ): SightcallEventSubscription {
    return this.registerCallbackWith<OnExtensionMissingArgs>(
      "extension.missing",
      callback,
      (...args) => {
        return {
          extensionUrl: args[0],
        };
      }
    );
  }
  /**
   * Registers a callback to be executed when the plugin is missing
   * @param callback the callback to execute
   */
  onPluginMissing(callback: () => void): SightcallEventSubscription {
    return this.registerCallback("plugin.missing", callback);
  }
  /**
   * Registers a callback to be executed when the plugin requires permission
   * @param callback the callback to execute
   */
  onPluginRequiresPermission(callback: () => void): SightcallEventSubscription {
    return this.registerCallback("plugin.requirePermission", callback);
  }
  /**
   * Registers a callback to be executed when the plugin is connected
   * @param callback the callback to execute
   */
  onPluginConnected(callback: () => void): SightcallEventSubscription {
    return this.registerCallback("plugin.connected", callback);
  }
  /**
   * Registers a callback to be executed when the plugin starts updating
   * @param callback the callback to execute
   */
  onPluginStartedUpdate(callback: () => void): SightcallEventSubscription {
    return this.registerCallback("plugin.startupdate", callback);
  }
  /**
   * Registers a callback to be executed when the plugin requires an update
   * @param callback the callback to execute
   */
  onPluginUpdate(callback: () => void): SightcallEventSubscription {
    return this.registerCallback("plugin.update", callback);
  }
  /**
   * Registers a callback to be executed when the console is logged out
   * @param callback the callback to execute
   */
  onConsoleLogout(callback: () => void): SightcallEventSubscription {
    return this.registerCallback("console.logout", callback);
  }
  /**
   * Registers a callback to be executed when an agent comes online
   * @param callback the callback to execute
   */
  onAgentOnline(callback: () => void): SightcallEventSubscription {
    return this.registerCallback("agent.online", callback);
  }
  /**
   * Registers a callback to be executed when an agent goes away
   * @param callback the callback to execute
   */
  onAgentAway(callback: () => void): SightcallEventSubscription {
    return this.registerCallback("agent.away", callback);
  }
  /**
   * Registers a callback to be executed when an agent becomes busy
   * @param callback the callback to execute
   */
  onAgentBusy(callback: () => void): SightcallEventSubscription {
    return this.registerCallback("agent.busy", callback);
  }

  /**
   * Registers a callback to be executed when the agent connects
   * @param callback the callback to execute
   */
  onAgentConnected(
    callback: (agent: string) => void
  ): SightcallEventSubscription {
    return this.registerCallbackWith<string>(
      "agent.connected",
      callback,
      (...args) => {
        return args[0];
      }
    );
  }
  /**
   * Registers a callback to be executed when the agent disconnects
   * @param callback the callback to execute
   */
  onAgentDisconnected(callback: () => void): SightcallEventSubscription {
    return this.registerCallback("agent.disconnected", callback);
  }
  /**
   * Registers a callback to be executed when a call is created
   * @param callback the callback to execute
   */
  onCallCreated(
    callback: (args: CallInformation) => void
  ): SightcallEventSubscription {
    return this.registerCallbackWith<CallInformation>(
      "call.created",
      callback,
      (...args) => {
        return args[0] as CallInformation;
      }
    );
  }
  /**
   * Registers a callback to be executed when a call is activated
   * @param callback
   * @returns
   */
  onCallActivated(
    callback: (args: CallInformation) => void
  ): SightcallEventSubscription {
    return this.registerCallbackWith<CallInformation>(
      "call.activated",
      callback,
      (...args) => {
        return args[0] as CallInformation;
      }
    );
  }
  /**
   * Registers a callback to be executed when a call is terminated
   * @param callback the callback to execute
   */
  onCallTerminated(callback: () => void): SightcallEventSubscription {
    return this.registerCallback("call.terminated", callback);
  }
  /**
   * Registers a callback to be executed when a user denies consent
   * @param callback the callback to execute
   */
  onGuestDenyConsent(callback: () => void): SightcallEventSubscription {
    return this.registerCallback("guest.consentdenied", callback);
  }
  /**
   * Registers a callback to be executed when ?????
   * @param callback the callback to execute
   */
  onButtonLocalSound(callback: () => void): SightcallEventSubscription {
    return this.registerCallback("button.localSound", callback);
  }
  /**
   * Registers a callback to be executed when settings are closed
   * @param callback the callback to execute
   */
  onSettingsClosed(callback: () => void): SightcallEventSubscription {
    return this.registerCallback("settings.closed", callback);
  }
  /**
   * Registers a callback to be executed when a peer is disconnected
   * @param callback the callback to execute
   */
  onPeerDisconnected(callback: () => void): SightcallEventSubscription {
    return this.registerCallback("peer.disconnected", callback);
  }
  /**
   * Registers a callback to be executed when a peer reconnects
   * @param callback the callback to execute
   */
  onPeerReconnect(callback: () => void): SightcallEventSubscription {
    return this.registerCallback("peer.reconnected", callback);
  }

  /**
   * Registers a callback to be executed when the remote camera is paused
   * @param callback the callback to be executed
   * @returns
   */
  onRemoteCameraPause(
    callback: (state: PlaybackState) => void
  ): SightcallEventSubscription {
    return this.registerCallbackWith<PlaybackState>(
      "remote.camera.pause",
      callback,
      (...args) => {
        return args[0] == true ? PlaybackState.pause : PlaybackState.play;
      }
    );
  }
  /**
   * Registers a callback to be executed when the local microphone state changed
   * @param callback the callback to be executed
   * @returns
   */
  onLocalMicrophone(
    callback: (state: MicrophoneState) => void
  ): SightcallEventSubscription {
    return this.registerCallbackWith<MicrophoneState>(
      "local.micro",
      callback,
      (...args) => {
        return args[0] == true ? MicrophoneState.on : MicrophoneState.off;
      }
    );
  }
  /**
   * Registers a callback to be executed when the local camera state changed
   * @param callback the callback to be executed
   * @returns
   */
  onLocalCamera(
    callback: (state: CameraState) => void
  ): SightcallEventSubscription {
    return this.registerCallbackWith<CameraState>(
      "local.camera",
      callback,
      (...args) => {
        return args[0] == true ? CameraState.on : CameraState.off;
      }
    );
  }
  /**
   * Registers a callback to be executed when the remote light state changed
   * @param callback the callback to be executed
   * @returns
   */
  onRemoteLight(
    callback: (state: LightState) => void
  ): SightcallEventSubscription {
    return this.registerCallbackWith<LightState>(
      "remote.light",
      callback,
      (...args) => {
        return args[0] == true ? LightState.on : LightState.off;
      }
    );
  }
  /**
   * Registers a callback to be executed when the local microphone state changed
   * @param callback the callback to be executed
   * @returns
   */
  onRemoteMicrophone(
    callback: (state: MicrophoneState) => void
  ): SightcallEventSubscription {
    return this.registerCallbackWith<MicrophoneState>(
      "remote.micro",
      callback,
      (...args) => {
        return args[0] == true ? MicrophoneState.on : MicrophoneState.off;
      }
    );
  }
  /**
   * Registers a callback to be executed when the local camera state changed
   * @param callback the callback to be executed
   * @returns
   */
  onRemoteCamera(
    callback: (state: CameraState) => void
  ): SightcallEventSubscription {
    return this.registerCallbackWith<CameraState>(
      "remote.camera",
      callback,
      (...args) => {
        return args[0] == true ? CameraState.on : CameraState.off;
      }
    );
  }
  /**
   * Registers a callback to be executed when an image of the guests camera feed has been saved
   * @param callback the callback to execute
   */
  onRemoteImageSaved(callback: () => void): SightcallEventSubscription {
    return this.registerCallback("remote.save", callback);
  }

  /**
   * Registers a callback to be executed when a picture has been saved.
   * This callback is only executed after the call is closed, and saving has been confirmed
   * @param callback the callback to be executed
   * @returns
   */
  onPictureSaved(
    callback: (state: OnPictureSavedArgs) => void
  ): SightcallEventSubscription {
    return this.registerCallbackWith<OnPictureSavedArgs>(
      "picture.saved",
      callback,
      (...args) => {
        var origin: PictureOrigin = PictureOrigin.camera;
        switch (args[0].reference) {
          case "camera":
            origin = PictureOrigin.camera;
            break;
          case "photo":
            origin = PictureOrigin.photo;
            break;
          case "video":
            origin = PictureOrigin.video;
            break;
          case "picture":
            origin = PictureOrigin.picture;
            break;
          case "screencast":
            origin = PictureOrigin.screencast;
            break;
          case "proposition":
            origin = PictureOrigin.proposition;
            break;
          case "ocr":
            origin = PictureOrigin.ocr;
            break;
          case "measure":
            origin = PictureOrigin.measure;
            break;
          case "gpsmap":
            origin = PictureOrigin.gpsmap;
            break;
          case "url":
            origin = PictureOrigin.url;
            break;
        }
        return {
          callDuration: args[0].call_duration,
          caseId: args[0].case_id,
          contentBase64: args[0].content,
          latitude: args[0].latitude,
          longitude: args[0].longitude,
          ocrText: args[0].ocr_text,
          reference: args[0].reference,
          useCase: args[0].usecase,
          origin: origin,
        };
      }
    );
  }

  /**
   * Registers a callback to be executed when an ACD request is received
   * @param callback the callback to execute
   */
  onACDRequestReceived(
    callback: (args: CallInformation) => void
  ): SightcallEventSubscription {
    return this.registerCallbackWith<CallInformation>(
      "request.received",
      callback,
      (...args) => {
        return args[0] as CallInformation;
      }
    );
  }
  /**
   * Registers a callback to be executed when an ACD request is accepted
   * @param callback the callback to execute
   */
  onACDRequestAccepted(
    callback: (args: CallInformation) => void
  ): SightcallEventSubscription {
    return this.registerCallbackWith<CallInformation>(
      "request.accepted",
      callback,
      (...args) => {
        return args[0] as CallInformation;
      }
    );
  }
  /**
   * Registers a callback to be executed when an ACD request is declined
   * @param callback the callback to execute
   */

  onACDRequestDeclined(
    callback: (args: CallInformation) => void
  ): SightcallEventSubscription {
    return this.registerCallbackWith<CallInformation>(
      "request.declined",
      callback,
      (...args) => {
        return args[0] as CallInformation;
      }
    );
  }

  onRtccCoreDumpReceived(
    callback: (args: string) => void
  ): SightcallEventSubscription {
    return this.registerCallbackWith<string>(
      "coredump.retrieved",
      callback,
      (...args) => {
        return args[0];
      }
    );
  }

  getRtccCoreDump(): void {
    this.checkIfInitialised();
    this.sightCall.getRtccCoreDump();
  }

  /**
   * Removes a callback from an event
   * @param event the event to remove the callback from
   * @param callback the callback to remove
   */
  unregisterCallback(event: string, callback: Function) {
    this.checkIfInitialised();
    this.sightCall.off(event, callback);
  }
}

export interface OnExtensionMissingArgs {
  extensionUrl: string;
}
export interface OnConsoleResizedArgs {
  width: number;
  height: number;
}
export interface OnPictureSavedArgs {
  callDuration: number;
  caseId: string;
  contentBase64: string;
  latitude: string;
  longitude: string;
  ocrText: string;
  origin: PictureOrigin;
  reference: string;
  useCase: string;
}
export interface OnGuestLocationReceivedArgs {
  location: GuestLocation;
}
export interface GuestLocation {
  latitude: number;
  longitude: number;
}
export interface CallInformation {
  uid: string;
  invitationId: string;
  number: number;
  reference: string;
  sixDigits: string;
  device: string;
  os: string;
  clientMode: string;
  ip: string;
  isp: string;
  simType: string;
  simCarrier: string;
  simStrength: string;
  wifiSsid: string;
  wifiSpeed: string;
  wifiStrength: string;
  batteryLevel: number;
  batteryCharging: boolean;
  batteryPlugged: string;
  isAccepted: boolean;
  isPending: boolean;
  isCallStarted: boolean;
  isGeolocationRequested: boolean;
  isTerminated: boolean;
  acdRequest: string;
  isCameraStarted: boolean;
  isCameraUsbStarted: boolean;
  isCameraPaused: boolean;
  cameraSource: string;
  cameraZoom: number;
  isTorchAvailable: boolean;
  isTorchStarted: boolean;
  isMicroStarted: boolean;
  isPlayerStarted: boolean;
  isPlayerPaused: boolean;
  playerSeek: boolean;
  isShareStarted: boolean;
  shareType: string;
  shareAction: string;
  shareUrl: string;
  isControlStarted: boolean;
  isStatusReceived: boolean;
  isCollimatorStarted: boolean;
  isOcrResolved: boolean;
  arState: string;
  liveQrState: boolean;
  presence: string;
  push_button: string;
  gps: string;
  usecase: string;
  product: string;
  language: string;
  location: string;
  pincode: string;
  externalRequest: string;
  multiPartyAgent: string;
}
export enum PictureOrigin {
  camera,
  photo,
  video,
  picture,
  screencast,
  proposition,
  ocr,
  measure,
  gpsmap,
  url,
}
export enum MicrophoneState {
  on,
  off,
}
export enum CameraState {
  on,
  off,
}
export enum LightState {
  on,
  off,
}
export enum PlaybackState {
  pause,
  play,
}
export enum AgentPresence {
  online,
  away,
  busy,
}
export enum AgentConnectionState {
  connected,
  disconnected,
}

/**
 * Class to unregister from an event scubscription
 */
export class SightcallEventSubscription {
  callback: Function;
  sightcall: SightcallConsole;
  event: string;
  isUnregistered: boolean = false;

  constructor(event: string, callback: Function, sightcall: SightcallConsole) {
    this.event = event;
    this.callback = callback;
    this.sightcall = sightcall;
  }

  /**
   * Cancels the event subscription
   * @returns
   */
  cancel(): void {
    if (this.isUnregistered) {
      return;
    }
    this.sightcall.unregisterCallback(this.event, this.callback);
  }
}

export default SightcallConsole;
