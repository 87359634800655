import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HttpStatusCode,
} from "axios";
import { z } from "zod";
import { ITokenService } from "../tokenService/ITokenService";

export abstract class IJoeApi {
  // Calls
  abstract getCall(id: string): Promise<GetCallDTO | null>;
  abstract getCallsByUser(id: string): Promise<GetCallsByUserDTO | null>;
  abstract postCall(dto: PostCallRequestDTO): Promise<PostCallResponseDTO>;

  // Coffeemachine Readout
  abstract getCoffeemachineReadout(
    id: string
  ): Promise<GetCoffeeMachineReadoutDTO | null>;

  // Joe Readout
  abstract getJoeReadout(id: string): Promise<GetJoeReadoutDTO | null>;

  // Joe User
  abstract getJoeUser(id: string): Promise<GetJoeUserDTO | null>;

  //XML Files
  abstract getXMLFile(efNumber: string): Promise<string | null>;
}

export class JoeApi implements IJoeApi {
  private _baseUrl: string;
  private _axiosInstance: AxiosInstance;
  private _tokenService: ITokenService;

  constructor(baseUrl: string, tokenService: ITokenService) {
    this._baseUrl = baseUrl;
    this._tokenService = tokenService;
    this._axiosInstance = axios.create();
  }

  async getCallsByUser(id: string): Promise<GetCallsByUserDTO | null> {
    return await this._get(
      `/api/customer-support/get-calls/${id}`, //
      (response) => {
        if (response.status == HttpStatusCode.Ok) {
          return GetCallsByUserDTOSchema.parse(response.data);
        } else if (response.status == HttpStatusCode.NotFound) {
          return null;
        }
        throw {} as ApiException;
      }
    );
  }

  async getCall(id: string): Promise<GetCallDTO | null> {
    return await this._get(
      `/api/customer-support/get-call/${id}`, //
      (response) => {
        if (response.status == HttpStatusCode.Ok) {
          return GetCallDTOSchema.parse(response.data);
        } else if (response.status == HttpStatusCode.NotFound) {
          return null;
        }
        throw {} as ApiException;
      }
    );
  }

  async postCall(data: PostCallRequestDTO): Promise<PostCallResponseDTO> {
    return await this._post(
      `/api/customer-support/create-entry`,
      data,
      (response) => {
        if (response.status == HttpStatusCode.Ok) {
          return PostCallResponseDTOSchema.parse(response.data);
        }
        throw {} as ApiException;
      }
    );
  }

  async getCoffeemachineReadout(
    id: string
  ): Promise<GetCoffeeMachineReadoutDTO | null> {
    return await this._get(
      `/api/customer-support/get-coffee-machine-readout/${id}`, //
      (response) => {
        if (response.status == HttpStatusCode.Ok) {
          return GetCoffeeMachineReadoutDTOSchema.parse(response.data);
        } else if (response.status == HttpStatusCode.NotFound) {
          return null;
        }
        throw {} as ApiException;
      }
    );
  }

  async getJoeReadout(id: string): Promise<GetJoeReadoutDTO | null> {
    return await this._get(
      `/api/customer-support/get-joe-readout/${id}`, //
      (response) => {
        if (response.status == HttpStatusCode.Ok) {
          return GetJoeReadoutDTOSchema.parse(response.data);
        } else if (response.status == HttpStatusCode.NotFound) {
          return null;
        }
        throw {} as ApiException;
      }
    );
  }

  async getJoeUser(id: string): Promise<GetJoeUserDTO | null> {
    return await this._get(
      `/api/customer-support/get-user-info/${id}`, //
      (response) => {
        if (response.status == HttpStatusCode.Ok) {
          return GetJoeUserDTOSchema.parse(response.data);
        } else if (response.status == HttpStatusCode.NotFound) {
          return null;
        }
        throw {} as ApiException;
      }
    );
  }

  /**
   *
   * @param efNumber the ef number to get e.g. "EF345"
   * @returns
   */
  async getXMLFile(efNumber: string): Promise<string | null> {
    return await this._get(
      `/api/documents/xml/${efNumber}`, //
      (response) => {
        if (response.status == HttpStatusCode.Ok) {
          return response.data;
        } else if (response.status == HttpStatusCode.NotFound) {
          return null;
        }
        throw {} as ApiException;
      }
    );
  }

  private async _get<T>(
    path: string,
    handle: (response: AxiosResponse<any, any>) => T
  ): Promise<T> {
    var request: AxiosRequestConfig = {
      method: "GET",
      url: `${this._baseUrl}${path}`,
      headers: {
        Authorization: `Bearer ${this._tokenService.accessToken}`,
        "Content-Type": `application/json`,
      },
      validateStatus: null,
    };

    let response = await this._request(request);

    return handle(response);
  }

  private async _post<T, TData>(
    path: string,
    data: TData,
    handle: (response: AxiosResponse<any, any>) => T
  ): Promise<T> {
    var request: AxiosRequestConfig = {
      method: "POST",
      url: `${this._baseUrl}${path}`,
      headers: {
        //Authorization: `Bearer ${this._tokenService.accessToken}`,
        "Content-Type": `application/json`,
      },
      data: data,
      validateStatus: null,
    };

    let response = await this._request(request);

    return handle(response);
  }

  private async _request<T>(
    config: AxiosRequestConfig<T>
  ): Promise<AxiosResponse> {
    //perform request
    try {
      let response = await this._axiosInstance.request(config);

      //if not unauthorized, return
      if (response.status != HttpStatusCode.Unauthorized) {
        return response;
      }

      //if unauthorized, refresh the access token
      await this._tokenService.updateAccessToken();

      //Change the config to use the new access token
      var newConfig = {
        ...config,
        header: {
          ...config.headers,
          Authorization: `Bearer ${this._tokenService.accessToken}`,
        },
      };

      //perform new request
      let newResponse = await this._axiosInstance.request(newConfig);

      return newResponse;
    } catch (e) {
      throw e;
    }
  }
}

interface ApiException {}

//Zod Schemas
const PostCallRequestDTOSchema = z.object({
  userId: z.string().nullable(),
  joeReadout: z
    .object({
      appVersion: z.string(),
      os: z.string(),
      osVersion: z.string(),
      deviceLanguage: z.string(),
      deviceModel: z.string(),
      bleEnabled: z.boolean(),
      wifiEnabled: z.boolean(),
      forEfCode: z.string().nullable(),
      products: z.array(
        z.object({
          id: z.string(),
          customName: z.string().nullable(),
          parameters: z.array(
            z.object({
              id: z.string(),
              value: z.string(),
            })
          ),
          preselections: z.array(
            z.enum([
              "xtrashot",
              "coldbrew",
              "double",
              "powder",
              "sweetfoam",
              "fakesweetfoam",
            ])
          ),
        })
      ),
      settings: z.array(
        z.object({
          id: z.string(),
          value: z.string(),
        })
      ),
      permissions: z.array(
        z.object({
          name: z.string(),
          granted: z.boolean(),
        })
      ),
    })
    .nullable(),
  coffeeMachineReadout: z
    .object({
      // timestamp: z.string(),
      uniqueId: z.string(),
      modelName: z.string(),
      efCode: z.string(),
      articleNumber: z.string(),
      softwareVersion: z.string(),
      name: z.string(),
      pinEnabled: z.boolean(),
      frogType: z.enum(["smartConnect", "wifiConnect"]).nullable(),
      connectionType: z.enum(["bluetooth", "wifi"]).nullable(),
      alerts: z.array(
        z.object({
          id: z.string(),
        })
      ),
      maintenanceCounter: z.array(
        z.object({
          id: z.string(),
          count: z.number(),
        })
      ),
      maintenanceStatus: z.array(
        z.object({
          id: z.string(),
          percent: z.number(),
        })
      ),
      products: z.array(
        z.object({
          id: z.string(),
          consumptions: z.number(),
          parameters: z.array(
            z.object({
              id: z.string(),
              value: z.string(),
            })
          ),
        })
      ),
      settings: z.array(
        z.object({
          id: z.string(),
          value: z.string(),
        })
      ),
    })
    .nullable(),
});

const PostCallResponseDTOSchema = z.object({
  id: z.string(),
});

const GetJoeUserDTOSchema = z.object({
  user_id: z.string(),
  given_name: z.string(),
  last_name: z.string(),
  email: z.string(),
});

const GetCallDTOSchema = z.object({
  id: z.string(),
  data: z.object({
    userId: z.string().nullable(),
    joeReadoutId: z.string().nullable(),
    coffeeMachineReadoutId: z.string().nullable(),
    timeStamp: z.string(),
  }),
});

const CoffeeMachineDTOSchema = z.object({
  // timestamp: z.string(),
  uniqueId: z.string(),
  modelName: z.string(),
  efCode: z.string(),
  articleNumber: z.string(),
  softwareVersion: z.string(),
  name: z.string(),
  pinEnabled: z.boolean(),
  frogType: z.enum(["smartConnect", "wifiConnect"]).nullable(),
  connectionType: z.enum(["bluetooth", "wifi"]).nullable(),
  alerts: z.array(
    z.object({
      id: z.string(),
    })
  ),
  maintenanceCounter: z.array(
    z.object({
      id: z.string(),
      count: z.number(),
    })
  ),
  maintenanceStatus: z.array(
    z.object({
      id: z.string(),
      percent: z.number(),
    })
  ),
  products: z.array(
    z.object({
      id: z.string(),
      consumptions: z.number(),
      parameters: z.array(
        z.object({
          id: z.string(),
          value: z.string(),
        })
      ),
    })
  ),
  settings: z.array(
    z.object({
      id: z.string(),
      value: z.string(),
    })
  ),
});

const GetJoeReadoutDTOSchema = z.object({
  id: z.string(),
  data: z.object({
    appVersion: z.string(),
    os: z.string(),
    osVersion: z.string(),
    deviceLanguage: z.string(),
    deviceModel: z.string(),
    bleEnabled: z.boolean(),
    wifiEnabled: z.boolean(),
    forEfCode: z.string().nullable(),
    products: z.array(
      z.object({
        id: z.string(),
        customName: z.string().nullable(),
        parameters: z.array(
          z.object({
            id: z.string(),
            value: z.string(),
          })
        ),
        preselections: z.array(
          z.enum([
            "xtrashot",
            "coldbrew",
            "double",
            "powder",
            "sweetfoam",
            "fakesweetfoam",
          ])
        ),
      })
    ),
    settings: z.array(
      z.object({
        id: z.string(),
        value: z.string(),
      })
    ),
    permissions: z.array(
      z.object({
        name: z.string(),
        granted: z.boolean(),
      })
    ),
  }),
});

const GetCoffeeMachineReadoutDTOSchema = z.object({
  id: z.string(),
  data: CoffeeMachineDTOSchema,
});

const GetCoffeeMachineReadoutsByUserIdDTOSchema = z.array(
  CoffeeMachineDTOSchema.augment({
    id: z.string(),
  })
);

const GetCallsByUserDTOSchema = z.array(
  z.object({
    id: z.string(),
    userId: z.string().nullable(),
    joeReadoutId: z.string().nullable(),
    coffeeMachineReadoutId: z.string().nullable(),
    timeStamp: z.string(),
  })
);

//Generate Types from Zod Schemas
export type PostCallRequestDTO = z.infer<typeof PostCallRequestDTOSchema>;
export type PostCallResponseDTO = z.infer<typeof PostCallResponseDTOSchema>;
export type GetJoeUserDTO = z.infer<typeof GetJoeUserDTOSchema>;
export type GetCallDTO = z.infer<typeof GetCallDTOSchema>;
export type GetCallsByUserDTO = z.infer<typeof GetCallsByUserDTOSchema>;
export type GetCoffeeMachineReadoutDTO = z.infer<
  typeof GetCoffeeMachineReadoutDTOSchema
>;
export type CoffeeMachineReadoutDTO = z.infer<typeof CoffeeMachineDTOSchema>;
export type GetJoeReadoutDTO = z.infer<typeof GetJoeReadoutDTOSchema>;
