import { useKeycloak } from "@react-keycloak/web";
import { MouseEventHandler } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PageRoutes from "../../../PageRoutes";
import { AppViewmodel } from "../../../Application";
import { MaterialIcon as MaterialIcons } from "material-icons";
import MaterialIcon from "../../../components/MaterialIcon/MaterialIcon";

interface SideMenuProps {
  activeRoute: string;
  vm: AppViewmodel;
}

function SideMenu(props: SideMenuProps): JSX.Element {
  const { t, i18n } = useTranslation("translation", { keyPrefix: "sideMenu" });
  const navigate = useNavigate();
  const { initialized, keycloak } = useKeycloak();

  let logout = async () => {
    props.vm.signOut();
  };

  return (
    <div className="px-0 pt-12 min-w-[16rem] border-r h-full">
      <div className="flex flex-col">
        <SideMenuMenuItem
          icon={<MaterialIcon icon="forum"></MaterialIcon>}
          title={<div>{t("AdvisorConsole")}</div>}
          onClick={() => {
            navigate(PageRoutes.AdvisoryScreenPage);
          }}
          isSelected={
            props.activeRoute == PageRoutes.AdvisoryScreenPage ||
            props.activeRoute == ""
          }
        ></SideMenuMenuItem>
        <SideMenuMenuItem
          icon={<MaterialIcon icon="logout" />}
          title={<div>{t("Logout")}</div>}
          onClick={() => {
            logout();
          }}
        ></SideMenuMenuItem>
      </div>
    </div>
  );
}

interface SideMenuMenuItemProps {
  icon?: JSX.Element;
  title?: JSX.Element;
  onClick?: MouseEventHandler<HTMLElement>;
  isSelected?: boolean;
}

function SideMenuMenuItem(props: SideMenuMenuItemProps): JSX.Element {
  var selectedClass = props.isSelected ? "text-jura-brown-60" : "text-black";

  return (
    <div
      className={[
        "flex flex-row gap-8 cursor-pointer select-none transition bg-white hover:brightness-90 px-4 py-3 items-center",
        selectedClass,
      ].join(" ")}
      onClick={props.onClick}
    >
      {props.icon}
      <div className="flex-1 text-sm">{props.title}</div>
    </div>
  );
}

export default SideMenu;
