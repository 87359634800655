import { CSSProperties, PropsWithChildren, useState } from "react";
import useInterval from "use-interval";

interface FollowerProps {
  tag: string; //The tag is used to match a Follower and FollowTarget
}
interface FollowTargetProperties {
  top: number;
  left: number;
  width: number;
  height: number;
  type: string;
}
/**
 * React Component with hosts any other element as its child. Periodically attempts to find a matching "FollowTarget" element.
 * Uses absolute placement to match the position of its children with the position of the "FollowTarget"
 * @param props
 * @returns
 */
export function Follower(props: PropsWithChildren<FollowerProps>): JSX.Element {
  const [targetProperties, setTargetProperties] =
    useState<FollowTargetProperties | null>();
  useInterval(() => {
    var targetElement = document.getElementById(`follower-id-${props.tag}`);

    if (targetElement == null) {
      setTargetProperties(null);
      return;
    }

    const boundingRect = targetElement.getBoundingClientRect();
    const followTargetType = targetElement.getAttribute(
      "follow-target-type"
    ) as string;

    const newProperties: FollowTargetProperties = {
      top: boundingRect.top,
      left: boundingRect.left,
      width: boundingRect.width,
      height: boundingRect.height,
      type: followTargetType,
    };
    setTargetProperties(newProperties);
  }, 20);

  const css: CSSProperties = {};

  //If a FollowTarget is found
  if (targetProperties != null) {
    //If the FollowTarget is of type FollowerTypeContainer (Match the position and size)
    if (targetProperties.type == FollowerTypeContain) {
      css.position = "fixed";
      css.zIndex = 10;
      css.left = targetProperties.left;
      css.top = targetProperties.top;
      css.width = targetProperties.width;
      css.height = targetProperties.height;
      css.transition = "all 0.2s ease-out";
    }
    //If the FollowTarget is of type FollowerTypeScale (Match the position and match the size by scaling)
    else if (targetProperties.type == FollowerTypeScale) {
      const targetAspect = targetProperties.width / targetProperties.height;
      css.top = targetProperties.top;
      css.left = targetProperties.left;
      css.zIndex = 1;
      css.position = "fixed";
      css.width = targetAspect * window.innerHeight;
      css.height = window.innerHeight;
      css.transform = `scale(${targetProperties.height / window.innerHeight},${
        targetProperties.height / window.innerHeight
      })`;
      css.transformOrigin = "top left";
      css.transition = "all 0.2s ease-out";
    }
  }
  //If no FollowTarget is found, dont show the Follower child
  else {
    css.display = "none";
    css.transition = "all 0.2s ease-out";
  }
  return <div style={css}>{props.children}</div>;
}

export const FollowerTypeContain = "FollowerTypeContain";
export const FollowerTypeScale = "FollowerTypeScale";

interface FollowTargetProps {
  tag: string; //The tag is used to match a Follower and FollowTarget
  type: string; //Controls the way the Follwer is rendered
}
/**
 * React Component that can be followed by a "Follower" Component.
 * @param props
 * @returns
 */
export function FollowTarget(
  props: PropsWithChildren<FollowTargetProps>
): JSX.Element {
  return (
    <div
      style={{ height: "100%", width: "100%" }}
      id={`follower-id-${props.tag}`}
      follow-target-type={props.type}
    >
      {props.children}
    </div>
  );
}
