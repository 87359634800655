import {
  CoffeeMachineReadoutDTO,
  GetCoffeeMachineReadoutDTO,
  GetJoeReadoutDTO,
  IJoeApi,
} from "./joeApi";
import Call from "./models/call";
import CoffeeMachineReadout from "./models/coffeeMachineReadout";
import JoeReadout from "./models/joeReadout";

import JoeUser from "./models/joeUser";

export abstract class ICallDataService {
  abstract getCall(id: string): Promise<Call | null>;
}

export class MockCallDataService implements ICallDataService {
  async getCall(id: string): Promise<Call | null> {
    await new Promise((r) => setTimeout(r, 4000));

    return {
      id: "some call id",
      timestamp: new Date(Date.now()),
      joeUser: {
        id: "someid",
        firstName: "Max",
        lastName: "Mustermann",
        email: "Max@mustermann.ch",
        contactInformation: [
          {
            street: "Vorstadt 38",
            zipCode: "3380",
            city: "wangen an der Aaaaaaaaaaaaaaaaaaaaaaaaaaaare",
            state: "Bern",
            phoneNumber: "079 559 29 34",
            country: "CH",
          },
          {
            street: "Brauereistrasse",
            zipCode: "77560",
            city: "Bayern",
            state: "München",
            phoneNumber: "079 559 29 34",
            country: "DE",
          },
        ],
      },
      joeReadout: {
        id: "some joe id",
        timestamp: new Date(Date.now()),
        appVersion: "appVersion",
        os: "os",
        osVersion: "osVersion",
        deviceLanguage: "deviceLanguage",
        bleEnabled: false,
        wifiEnabled: true,
        deviceModel: "deviceModel",
        forEfCode: "EF565",
        products: [],
        settings: [],
        permissions: [],
      },
      coffeeMachineReadout: {
        id: "some cm id",
        timestamp: new Date(Date.now()),
        uniqueId: "uniqueId",
        modelName: "modelName",
        efCode: "EF1070",
        articleNumber: "articleNr",
        softwareVersion: "softwareVersion",
        name: "name",
        pinEnabled: false,
        frogType: "smartConnect",
        connectionType: "bluetooth",
        alerts: [
          {
            id: "1",
          },
          {
            id: "2",
          },
          {
            id: "13",
          },
          {
            id: "4",
          },
          {
            id: "5",
          },
          {
            id: "6",
          },
          {
            id: "7",
          },
        ],
        settings: [
          {
            id: "09",
            value: "02",
          },
        ],
        maintenanceCounter: [
          { id: "33", count: 100 },
          { id: "34", count: 33 },
        ],
        maintenanceStatus: [
          { id: "37", percent: 20 },
          { id: "38", percent: 98 },
        ],
        products: [
          {
            id: "0D",
            consumptions: 10,
            parameters: [],
          },
          {
            id: "01",
            consumptions: 12,
            parameters: [
              {
                id: "F3",
                value: "09",
              },
              {
                id: "F7",
                value: "01",
              },
              {
                id: "F4",
                value: "64",
              },
            ],
          },
          {
            id: "02",
            consumptions: 14,
            parameters: [],
          },
          {
            id: "03",
            consumptions: 100,
            parameters: [],
          },
        ],
      },
      otherCoffeeMachineReadouts: [],
    };
  }
}
export class CallDataService implements ICallDataService {
  private _api: IJoeApi;

  constructor(api: IJoeApi) {
    this._api = api;
  }

  async getCall(id: string): Promise<Call | null> {
    //read call by id
    let call = await this._api.getCall(id);

    if (call == null) {
      return null;
    }

    //read joeUser by id if availabe
    let joeUser: JoeUser | null = null;
    if (call.data.userId != null) {
      let dto = await this._api.getJoeUser(call.data.userId);

      if (dto != null) {
        joeUser = {
          id: dto.user_id,
          firstName: dto.given_name,
          lastName: dto.last_name,
          email: dto.email,
          contactInformation: [],
        };
      }
    }

    //read joeReadout by id if available
    let joeReadout: JoeReadout | null = null;
    if (call.data.joeReadoutId != null) {
      let dto = await this._api.getJoeReadout(call.data.joeReadoutId);

      if (dto != null) {
        joeReadout = _JoeReadout.parse(dto);
      }
    }

    //read coffeeMachineReadout by id if available
    let coffeeMachineReadout: CoffeeMachineReadout | null = null;
    if (call.data.coffeeMachineReadoutId != null) {
      let dto = await this._api.getCoffeemachineReadout(
        call.data.coffeeMachineReadoutId
      );

      if (dto != null) {
        coffeeMachineReadout = _CoffeeMachineReadout.parse(dto);
      }
    }

    //read coffeeMachineReadouts by userId if available
    let otherCoffeeMachineReadouts: CoffeeMachineReadout[] = [];
    // if (call.data.userId != null) {
    //   var userCalls = await this._api.getCallsByUser(call.data.userId);

    //   if(userCalls != null){
    //     userCalls.forEach(call => {

    //       if(call.coffeeMachineReadoutId == null){

    //       }

    //     })
    //   }
    // }

    //Set the timestamps of the joereadout based on the call timestamp
    if (joeReadout != null) {
      joeReadout.timestamp = new Date(call.data.timeStamp);
    }

    //Set the timestamps of the coffeemachinereadout based on the call timestamp
    if (coffeeMachineReadout != null) {
      coffeeMachineReadout!.timestamp = new Date(call.data.timeStamp);
    }

    return {
      id: call.id,
      timestamp: new Date(call.data.timeStamp),
      joeUser: joeUser,
      coffeeMachineReadout: coffeeMachineReadout,
      joeReadout: joeReadout,
      otherCoffeeMachineReadouts: [],
    };
  }
}

namespace _CoffeeMachineReadout {
  export function parse(dto: GetCoffeeMachineReadoutDTO): CoffeeMachineReadout {
    let out: CoffeeMachineReadout = {
      id: dto.id,
      timestamp: new Date(0),
      uniqueId: dto.data.uniqueId,
      modelName: dto.data.modelName,
      efCode: dto.data.efCode,
      articleNumber: dto.data.articleNumber,
      softwareVersion: dto.data.softwareVersion,
      name: dto.data.name,
      pinEnabled: dto.data.pinEnabled,
      frogType: dto.data.frogType ?? "mock",
      connectionType: dto.data.connectionType ?? "mock",
      alerts: dto.data.alerts.map<CoffeeMachineReadout.Alert>((dto) => ({
        id: dto.id,
      })),
      maintenanceCounter:
        dto.data.maintenanceCounter.map<CoffeeMachineReadout.MaintenanceCount>(
          (dto) => ({
            id: dto.id,
            count: dto.count,
          })
        ),
      maintenanceStatus:
        dto.data.maintenanceStatus.map<CoffeeMachineReadout.MaintenanceStatus>(
          (dto) => ({
            id: dto.id,
            percent: dto.percent,
          })
        ),
      products: dto.data.products.map<CoffeeMachineReadout.Product>((dto) => ({
        id: dto.id,
        consumptions: dto.consumptions,
        parameters:
          dto?.parameters?.map<CoffeeMachineReadout.ProductParameter>(
            (dto) => ({
              id: dto.id,
              value: dto.value,
            })
          ) ?? [],
      })),
      settings: dto.data.settings.map<CoffeeMachineReadout.Setting>((dto) => ({
        id: dto.id,
        value: dto.value,
      })),
    };

    return out;
  }
}
namespace _JoeReadout {
  export function parse(dto: GetJoeReadoutDTO): JoeReadout {
    let out: JoeReadout = {
      id: dto.id,
      timestamp: new Date(0),
      appVersion: dto.data.appVersion,
      os: dto.data.os,
      osVersion: dto.data.osVersion,
      deviceLanguage: dto.data.deviceLanguage,
      deviceModel: dto.data.deviceModel,
      bleEnabled: dto.data.bleEnabled,
      wifiEnabled: dto.data.wifiEnabled,
      forEfCode: dto.data.forEfCode,
      settings: dto.data.settings.map<JoeReadout.Setting>((dto) => ({
        value: dto.value,
        id: dto.id,
      })),
      permissions: dto.data.permissions.map<JoeReadout.Permission>((dto) => ({
        name: dto.name,
        granted: dto.granted,
      })),
      products: dto.data.products.map<JoeReadout.Product>((dto) => ({
        id: dto.id,
        customName: dto.customName,
        parameters: dto.parameters.map<JoeReadout.ProductParameter>((dto) => ({
          ...dto,
        })),
        preselections: [...dto.preselections],
      })),
    };

    return out;
  }
}
namespace _JoeUser {}
