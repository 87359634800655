import CoffeeStrengthSlider from "../../components/CoffeeStrengthSlider/CoffeeStrengthSlider";
import JuraLogo from "../../components/JuraLogo/JuraLogo";
import OptionsDisplay from "../../components/OptionsDisplay/OptionsDisplay";
import RangeDisplay from "../../components/RangeDisplay/RangeDisplay";
import Switch from "../../components/Switch/Switch";
import UnderlineTitle from "../../components/UnderlineTitle/UnderlineTitle";

function UITestPage(): JSX.Element {
  return (
    <div className="w-full">
      <div className="h-10 w-40">
        <JuraLogo />
      </div>
      {/* Palette */}
      <div className="flex flex-col gap-2 mb-2">
        <div className="flex flex-row gap-2">
          <div className="h-10 w-10 bg-jura-brown-15"></div>
          <div className="h-10 w-10 bg-jura-brown-60"></div>
          <div className="h-10 w-10 bg-jura-brown-100"></div>
        </div>
        <div className="flex flex-row gap-2">
          <div className="h-10 w-10 bg-jura-chrome-15"></div>
          <div className="h-10 w-10 bg-jura-chrome-60"></div>
          <div className="h-10 w-10 bg-jura-chrome-100"></div>
        </div>
      </div>

      <div>
        <CoffeeStrengthSlider total={10} selected={4} />
      </div>

      <div className="w-80">
        <OptionsDisplay
          selectedIndex={1}
          values={["A", "AAAA AAAA AA A", "AAAAAAAAAAAAA", "AAAAAA"]}
        />
      </div>
      <div className="w-80">
        <RangeDisplay min={0} value={75} max={100} />
      </div>
      <div className="w-80">
        <Switch value={true} />
        <Switch value={false} />
      </div>
      <div>
        <UnderlineTitle text="Some Title" />
      </div>
    </div>
  );
}

export default UITestPage;
