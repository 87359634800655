export class CoffeeMachineTemplateTextProvider {
  private localizedTextMaps: Map<string, Map<string, string>>;
  private locale: string;

  constructor(localizedTextMaps: Map<string, Map<string, string>>) {
    this.localizedTextMaps = localizedTextMaps;
    this.locale = "EN";
  }

  setLocale(locale: string) {
    this.locale = locale;
  }

  t(key: string): string | null {
    return (
      this.localizedTextMaps.get(this.locale.toLocaleLowerCase())?.get(key) ??
      this.localizedTextMaps.get(this.locale.toLocaleUpperCase())?.get(key) ??
      key
    );
  }
}
