import SightcallConsole, {
  CallInformation,
} from "./features/sightcall/SightcallConsole";
import {
  CallDataService,
  ICallDataService,
  MockCallDataService,
} from "./features/joeApi/callDataService";
import { CoffeeMachineTemplate } from "./features/coffeeMachineTemplates/CoffeeMachineTemplate";
import {
  ITemplateService,
  TemplateService,
} from "./features/coffeeMachineTemplates/TemplateService";
import {
  ISightcallTokenService,
  SightcallTokenService,
} from "./features/sightcall/SightcallTokenService";
import JoeUser from "./features/joeApi/models/joeUser";
import CoffeeMachineReadout from "./features/joeApi/models/coffeeMachineReadout";
import JoeReadout from "./features/joeApi/models/joeReadout";
import { AuthContext } from "./App";
import { Viewmodel } from "./utilities/viewmodel/viewmodel";
import i18n, { i18n as I18n } from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./features/i18n//translations/en.json";
import de from "./features/i18n//translations/de.json";
import { IJoeApi, JoeApi } from "./features/joeApi/joeApi";
import axios, { AxiosRequestConfig } from "axios";
import { ILocationApi, LocationApi } from "./features/locationApi/LocationApi";
import { DebugLogger, ILogger, Logger } from "./features/logging/Logger";
import NotificationService from "./features/notifications/NotificationService";

export interface AppState {
  user: string;
  isInitialised: boolean;
  percentInitialised: number;
  disableAutomaticSignin: boolean;

  call: {
    callStart: Date;
    isLoadingData: boolean;
    user: JoeUser | null;
    coffeeMachine: {
      readout: CoffeeMachineReadout;
      template: CoffeeMachineTemplate;
    } | null;
    joe: {
      readout: JoeReadout;
      template: CoffeeMachineTemplate;
    } | null;
    otherCoffeeMachines: {
      readout: CoffeeMachineReadout;
      template: CoffeeMachineTemplate;
    }[];
  } | null;
  sightcallConsole: {
    state: "open" | "opening" | "closed";
    signedIn: boolean;
    error: SightcallError | null;
  };
}

export interface AppConfig {
  verbose: boolean;
  sightcallConsoleIframeId: string;
  joeApiBaseUrl: string;
  isDevEnvironment: boolean;
}

export class AppViewmodel extends Viewmodel<AppState> {
  //Configuration
  private _config: AppConfig;
  private _authContext: AuthContext;

  //Services
  private _sightcallConsole!: SightcallConsole;
  private _callDataService!: ICallDataService;
  private _joeApi!: IJoeApi;
  private _locationApi!: ILocationApi;
  private _templateService!: ITemplateService;
  private _sightcallTokenService!: ISightcallTokenService;
  private _i18n!: I18n;
  private _log!: ILogger;
  private _notificationService!: NotificationService;
  private _version: String;

  //Internal
  private _disconnects: Date[] = [];

  get templateService(): ITemplateService {
    return this._templateService;
  }
  get authContext(): AuthContext {
    return this._authContext;
  }
  get sightcallConsole(): SightcallConsole {
    return this._sightcallConsole;
  }

  get config(): AppConfig {
    return this._config;
  }

  constructor(config: AppConfig, authContext: AuthContext, version: string) {
    super({
      isInitialised: false,
      percentInitialised: 0,
      disableAutomaticSignin: false,
      call: null,
      user: authContext.user,
      sightcallConsole: {
        state: "closed",
        signedIn: false,
        error: null,
      },
    });

    this._config = config;
    this._authContext = authContext;
    this._version = version;
  }

  async init(): Promise<void> {
    //Init Logger
    await this._initLogger();
    this._state.percentInitialised = 5;
    this._log.debug("Initialised Logger");
    this._notifyStateChanged({ ...this._state });

    //Some version number for debugging
    this._log.info(`Version - ${this._version}`);

    //Init Notifications
    await this._initNotificationService();
    this._state.percentInitialised = 7;
    this._log.debug("Initialised NotificationService");
    this._notifyStateChanged({ ...this._state });

    //Initialise translations
    await this._initI18n();
    this._state.percentInitialised = 10;
    this._log.debug("Initialised i18n");
    this._notifyStateChanged({ ...this._state });

    //Initialise sightcall library
    await this._initSightcall();
    this._state.percentInitialised = 20;
    this._log.debug("Initialised sightcall");
    this._notifyStateChanged({ ...this._state });

    //Initialise CallData Service (JOE API)
    await this._initCallDataService();
    this._state.percentInitialised = 90;
    this._log.debug("Initialised callDataService");
    this._notifyStateChanged({ ...this._state });

    //Initialise Template Service (XML Files)
    await this._initTemplateService();
    this._state.percentInitialised = 95;
    this._log.debug("Initialised templateService");
    this._notifyStateChanged({ ...this._state });

    //Initialise LocationApi
    await this._initLocationApi();
    this._state.percentInitialised = 96;
    this._log.debug("Initialised locationApi");
    this._notifyStateChanged({ ...this._state });

    //Initialise SightcallToken Service (JOE API)
    await this._initSightcallTokenService();
    this._state.percentInitialised = 97;
    this._log.debug("Initialised tokenService");
    this._notifyStateChanged({ ...this._state });

    this._subscribeToSightcallConsoleEvents();
    this._log.debug("Subscribed to sightcall console events");
    this._subscribeToI18nEvents();
    this._log.debug("Subscribed to i18n events");

    //Start
    this._sightcallConsole.open();
    this._state.sightcallConsole.state = "opening";
    this._notifyStateChanged({ ...this._state });

    //Fake delay
    await new Promise((r) => setTimeout(r, 2000));

    //Finish initialisation
    this._state.isInitialised = true;
    this._state.percentInitialised = 100;
    this._log.info("Initialised Application");
    this._notifyStateChanged({ ...this._state });
  }

  async downloadDiagnostics(): Promise<void> {
    console.log("downloading all");

    this._sightcallConsole.getRtccCoreDump();

    //Store log
    this._saveFile(
      "dcs_log_" +
        new Date().toLocaleDateString() +
        "_" +
        new Date().toLocaleTimeString() +
        ".txt",
      this._log.dump()
    );

    //Store call data
    if (this._state.call != null) {
      console.log("downloading data");
      this._saveFile(
        "call_" +
          new Date().toLocaleDateString() +
          "_" +
          new Date().toLocaleTimeString() +
          ".txt",
        JSON.stringify(this._state.call)
      );
    }
  }

  async performTestCall(): Promise<void> {
    //only perform this call if we are in the dev environment
    if (this._config.isDevEnvironment) {
      //Create a new call data entry
      const postResponse = await this._joeApi.postCall({
        userId: null,
        joeReadout: {
          appVersion: "4.1",
          os: "Android",
          osVersion: "Android: 12, SDK: 31",
          deviceLanguage: "de",
          deviceModel: "Manufacturer: samsung, Model: SM-A536B, Brand: samsung",
          bleEnabled: true,
          wifiEnabled: true,
          forEfCode: "EF1031",
          settings: [
            {
              value: "5",
              id: "COUNTDOWN",
            },
            {
              value: "ml",
              id: "UNITS",
            },
          ],
          permissions: [
            {
              name: "android.permission.ACCESS_BACKGROUND_LOCATION",
              granted: false,
            },
            {
              name: "android.permission.ACCESS_COARSE_LOCATION",
              granted: true,
            },
            {
              name: "android.permission.ACCESS_FINE_LOCATION",
              granted: true,
            },
            {
              name: "android.permission.ACCESS_NETWORK_STATE",
              granted: true,
            },
            {
              name: "android.permission.ACCESS_WIFI_STATE",
              granted: true,
            },
            {
              name: "android.permission.BLUETOOTH",
              granted: false,
            },
            {
              name: "android.permission.BLUETOOTH_ADMIN",
              granted: false,
            },
            {
              name: "android.permission.BLUETOOTH_ADVERTISE",
              granted: false,
            },
            {
              name: "android.permission.BLUETOOTH_CONNECT",
              granted: true,
            },
            {
              name: "android.permission.BLUETOOTH_SCAN",
              granted: true,
            },
            {
              name: "android.permission.CAMERA",
              granted: true,
            },
            {
              name: "android.permission.CHANGE_NETWORK_STATE",
              granted: true,
            },
            {
              name: "android.permission.CHANGE_WIFI_MULTICAST_STATE",
              granted: true,
            },
            {
              name: "android.permission.CHANGE_WIFI_STATE",
              granted: true,
            },
            {
              name: "android.permission.INTERNET",
              granted: true,
            },
            {
              name: "android.permission.READ_EXTERNAL_STORAGE",
              granted: false,
            },
          ],
          products: [
            {
              id: "02",
              customName: "",
              parameters: [
                {
                  id: "F3",
                  value: "02",
                },
                {
                  id: "F4",
                  value: "2d",
                },
                {
                  id: "F7",
                  value: "02",
                },
              ],
              preselections: [],
            },
            {
              id: "03",
              customName: "",
              parameters: [
                {
                  id: "F3",
                  value: "02",
                },
                {
                  id: "F4",
                  value: "64",
                },
                {
                  id: "F7",
                  value: "01",
                },
              ],
              preselections: [],
            },
            {
              id: "0D",
              customName: "",
              parameters: [
                {
                  id: "F4",
                  value: "dc",
                },
                {
                  id: "F7",
                  value: "01",
                },
              ],
              preselections: [],
            },
            {
              id: "28",
              customName: "",
              parameters: [
                {
                  id: "F3",
                  value: "02",
                },
                {
                  id: "F4",
                  value: "46",
                },
                {
                  id: "F10",
                  value: "32",
                },
                {
                  id: "F7",
                  value: "02",
                },
              ],
              preselections: [],
            },
            {
              id: "29",
              customName: "",
              parameters: [
                {
                  id: "F3",
                  value: "02",
                },
                {
                  id: "F4",
                  value: "78",
                },
                {
                  id: "F10",
                  value: "64",
                },
                {
                  id: "F7",
                  value: "02",
                },
              ],
              preselections: [],
            },
          ],
        },
        coffeeMachineReadout: {
          uniqueId: "20210323048817",
          modelName: "E4 (EA)",
          efCode: "EF1031",
          articleNumber: "15435",
          softwareVersion: "EF1031M V01.05",
          name: "",
          pinEnabled: false,
          frogType: "smartConnect",
          connectionType: "bluetooth",
          alerts: [
            {
              id: "32",
            },
            {
              id: "34",
            },
            {
              id: "999",
            },
          ],
          maintenanceCounter: [
            {
              id: "Cleaning",
              count: 0,
            },
            {
              id: "FilterChange",
              count: 0,
            },
            {
              id: "Decalc",
              count: 0,
            },
            {
              id: "CoffeeRinse",
              count: 175,
            },
          ],
          maintenanceStatus: [
            {
              id: "Cleaning",
              percent: 100,
            },
            {
              id: "Decalc",
              percent: 0,
            },
          ],
          products: [
            {
              id: "02",
              consumptions: 24,
              parameters: [
                {
                  id: "F0",
                  value: "NONE_ITEM",
                },
                {
                  id: "F3",
                  value: "02",
                },
                {
                  id: "F4",
                  value: "50",
                },
                {
                  id: "F7",
                  value: "00",
                },
              ],
            },
            {
              id: "03",
              consumptions: 8,
              parameters: [
                {
                  id: "F0",
                  value: "NONE_ITEM",
                },
                {
                  id: "F3",
                  value: "02",
                },
                {
                  id: "F4",
                  value: "50",
                },
                {
                  id: "F7",
                  value: "01",
                },
              ],
            },
            {
              id: "0D",
              consumptions: 94,
              parameters: [
                {
                  id: "F4",
                  value: "6e",
                },
                {
                  id: "F7",
                  value: "02",
                },
              ],
            },
            {
              id: "28",
              consumptions: 1,
              parameters: [
                {
                  id: "F0",
                  value: "NONE_ITEM",
                },
                {
                  id: "F3",
                  value: "02",
                },
                {
                  id: "F4",
                  value: "50",
                },
                {
                  id: "F10",
                  value: "cd",
                },
                {
                  id: "F7",
                  value: "01",
                },
              ],
            },
            {
              id: "29",
              consumptions: 0,
              parameters: [
                {
                  id: "F0",
                  value: "NONE_ITEM",
                },
                {
                  id: "F3",
                  value: "03",
                },
                {
                  id: "F4",
                  value: "f0",
                },
                {
                  id: "F10",
                  value: "f0",
                },
                {
                  id: "F7",
                  value: "02",
                },
              ],
            },
            {
              id: "31",
              consumptions: 0,
              parameters: [],
            },
            {
              id: "36",
              consumptions: 1,
              parameters: [],
            },
            {
              id: "0F",
              consumptions: 0,
              parameters: [],
            },
          ],
          settings: [
            {
              id: "02",
              value: "10",
            },
            {
              id: "13",
              value: "21F0",
            },
          ],
        },
      });

      const id = postResponse.id;

      //Start sightcall call in an new tab and pass the created call data entry id as the reference
      window.open(
        `https://mobile.sightcall.com/call/fb50d4ed3f226b795780f0e6a997fa95917c4df2?displayname=${id}`,
        "_blank"
      );
    }
  }

  async signOut(): Promise<void> {
    // Prevent automatic sign in when we sign out!
    this._state.disableAutomaticSignin = true;
    this._notifyStateChanged({ ...this._state });

    //if the console is closed, lets log out of the authcontext directly
    if (!this._sightcallConsole.isOpen()) {
      this._authContext.logout();
      return;
    }

    //otherwise lets sign out of the sightcall console first, then sign out of the auth context later

    //subscribe to the required auth callback to confirm sign out was successful, and then log out of keycloak as well
    this._sightcallConsole.onConsoleLogout(() => {
      this._authContext.logout();
    });

    //start sightcall signout
    this._sightcallConsole.disconnect();
  }

  private async _authenticateSightcallConsole(): Promise<void> {
    //If we are signing out, don't respond to sign in requests
    if (this._state.disableAutomaticSignin == true) {
      return;
    }

    //Use the accessToken to acquire an ssoToken for the user described by the idToken
    try {
      var accessToken = this._authContext.tokenService.accessToken;
    } catch (e) {
      this._state.sightcallConsole.error = SightcallError.userDoesNotExist;
      this._notifyStateChanged({ ...this._state });
      return;
    }

    this._log.debug(`AccessToken: ${accessToken}`);

    if (accessToken != null) {
      try {
        var ssoToken = await this._sightcallTokenService.getSsoToken(
          this.authContext.user
        );

        //Authenticate with sightcall console using the ssoToken
        this._sightcallConsole.ssoConnect(ssoToken);
      } catch (e) {
        this._state.sightcallConsole.error = SightcallError.userDoesNotExist;
        this._sightcallConsole.close();
        this._notifyStateChanged({ ...this._state });
        return;
      }
    }
  }

  private async _callStarted(call: CallInformation): Promise<void> {
    this._log.info(`call ${call.reference} - started`);

    //clear the notification
    this._notificationService.clear();

    //When a call starts, set the call object and notify that additional data is being loaded
    this._state.call = {
      callStart: new Date(),
      isLoadingData: true,
      user: null,
      coffeeMachine: null,
      joe: null,
      otherCoffeeMachines: [],
    };
    this._notifyStateChanged({ ...this._state });

    try {
      //Get the call details (machine readout etc.)
      let callData = await this._callDataService.getCall(call.reference);
      if (callData != null) {
        this._log.info(`call ${call.reference} - got call data`);
        //extend coffee machine readout with the matching template
        var cmReadout = callData.coffeeMachineReadout;
        var cmTemplate = await this._templateService.getTemplate(
          cmReadout?.efCode ?? ""
        );

        this._log.info(
          `call ${call.reference} - template ${
            cmTemplate?.["@_Group"] ?? "NULL"
          }`
        );

        var coffeeMachine =
          cmReadout == null || cmTemplate == null
            ? null
            : {
                readout: cmReadout!,
                template: cmTemplate!,
              };

        //extend the jeo readout with the matching template
        var joeReadout = callData.joeReadout;
        var joeTemplate = await this._templateService.getTemplate(
          joeReadout?.forEfCode ?? ""
        );

        var joe =
          joeReadout == null || joeTemplate == null
            ? null
            : {
                readout: joeReadout,
                template: joeTemplate,
              };

        //extend the other machine data with the matching templates
        /*var otherMachines = await callData.otherCoffeeMachineReadouts.flatMap(
          async (readout) => {
            var template = await this._templateService.getTemplate(
              readout?.efCode ?? ""
            );

            return readout == null || template == null
              ? []
              : [
                  {
                    readout: readout,
                    template: template,
                  },
                ];
          }
        );*/

        // assign the data to state
        if (this._state.call != null) {
          this._state.call = {
            ...this._state.call,
            user: callData!.joeUser,
            coffeeMachine: coffeeMachine,
            joe: joe,
            otherCoffeeMachines: [],
          };
        }
      } else {
        this._log.info(`call ${call.reference} - call data is null`);
      }
    } catch (e) {
      this._log.error(
        `call ${call.reference} - fatal error when loading data - ${e}`
      );
    }

    this._log.info(
      `call ${call.reference} - ${JSON.stringify(this._state.call)}`
    );

    // signal that the data loading is done.
    if (this._state.call != null) {
      this._state.call = {
        ...this._state.call,
        isLoadingData: false,
      };
    }
    this._notifyStateChanged({ ...this._state });
  }

  private _callEnded() {
    //set call to null
    this._state.call = null;
    this._notifyStateChanged({ ...this._state });
  }

  private _callIncoming() {
    this._notificationService.notify(
      "JURA DCS",
      this._i18n.t("New call incoming")
    );
  }

  private _languageChanged(language: string) {
    console.log("saving language:" + language);
    //save the selection
    localStorage.setItem("jura.dcs.lang", language);
  }

  private _sightcallAgentConnected(agent: string) {
    //Force the user and the agent to be the same!
    if (agent.toLowerCase() != this.authContext.user.toLowerCase()) {
      this._log.error(
        `user: ${this.authContext.user} and agent: ${agent} are not the same!`
      );
      this._sightcallConsole.disconnect();
      this._sightcallConsole.close();
    } else {
      this._state.sightcallConsole.signedIn = true;
    }
    this._notifyStateChanged({ ...this._state });
  }

  private _sightcallAgentDisconnected() {
    //If an agent is disconnected, get rid of the call data!
    this._callEnded();

    /*
    //Check how often the user has disconnected, if frequently, sign then out, some other window is probably open with their login!
    var now = new Date();
    this._disconnects.push(now);

    
    //Prune disconnects list to 5 elements
    if (this._disconnects.length > 5) {
      this._disconnects = this._disconnects.splice(
        this._disconnects.length - 5
      );
    }

    //Check time between first and last disconnect
    let first = this._disconnects[0];
    let last = this._disconnects[this._disconnects.length - 1];
    var timeDiffMillis = last.getTime() - first.getTime();
    var numberOfDisconnects = this._disconnects.length;

    //Prevent division by 0
    if (timeDiffMillis == 0) {
      return;
    }

    //Calculate the frequency of disconnects per second
    var frequency = numberOfDisconnects / (timeDiffMillis / 1000);

    //If disconnecting every 20 seconds, 3 times in a row, probably the user is logged in in another window!
    if (frequency > 1 / 20 && numberOfDisconnects > 2) {
      window.localStorage.setItem("DOUBLE SIGNIN", `${last.toLocaleString()}`);
      //prevent automatic sign in
      this._state.disableAutomaticSignin = true;
      //notify that the user is logged in multiple times
      this._state.sightcallConsole.error = SightcallError.multipleLogins;
      this._notifyStateChanged({
        ...this._state,
      });
      //sign the user out

      this._sightcallConsole.disconnect();
    }
    */
  }

  private _sightcallConsoleOpened() {
    this._state.sightcallConsole.state = "open";
    this._notifyStateChanged({ ...this._state });
  }

  private _sightcallConsoleClosed() {
    this._state.sightcallConsole.state = "closed";
    this._notifyStateChanged({ ...this._state });
  }

  private _sightcallDumpReceived(dump: string) {
    this._saveFile(
      "coredump" +
        new Date().toLocaleDateString() +
        "_" +
        new Date().toLocaleTimeString() +
        ".txt",
      dump
    );
  }

  private async _initLogger() {
    if (this._config.isDevEnvironment) {
      this._log = new DebugLogger();
    } else {
      this._log = new Logger();
    }
  }

  private async _initNotificationService() {
    this._notificationService = new NotificationService();
    await this._notificationService.requestPermission();
  }

  private async _initI18n() {
    //read the savedLanguage from local storage
    var savedLanguage = localStorage.getItem("jura.dcs.lang");
    console.log("saved language:" + savedLanguage);
    //Put the loaded languages in the correct format
    const resources = {
      en: {
        translation: {
          ...en,
        },
      },
      de: {
        translation: {
          ...de,
        },
      },
    };

    //Init with reactI18next library to make i18n available to all react components
    await i18n
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({
        resources: resources,
        lng: savedLanguage ?? "en",
        supportedLngs: ["en", "de"],
        interpolation: {
          escapeValue: false, // react already safes from xss
        },
      });

    this._i18n = i18n;
  }

  private async _initSightcall() {
    //Sightcall console library must be initialised before creating a SightcallConsole object
    await SightcallConsole.init();

    this._sightcallConsole = new SightcallConsole(
      this._config.sightcallConsoleIframeId,
      this._config.verbose
    );
  }

  private async _initTemplateService() {
    this._templateService = new TemplateService(this._joeApi);

    await this._templateService.init();
  }

  private async _saveFile(fileName: string, text: string) {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", fileName);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  private async _initCallDataService() {
    //Create a JoeAPI instance
    this._joeApi = new JoeApi(
      this._config.joeApiBaseUrl,
      this._authContext.tokenService
    );

    //Create a callDataService instance
    this._callDataService = new CallDataService(this._joeApi);

    // this._callDataService = new MockCallDataService();
  }

  private async _initLocationApi() {
    //Create a LocationApi instance to get the location from the JURA phonenumber endpoint
    this._locationApi = new LocationApi();
  }

  private async _initSightcallTokenService() {
    this._sightcallTokenService = new SightcallTokenService(
      this._config.joeApiBaseUrl,
      this._authContext.tokenService
    );
  }

  private _subscribeToSightcallConsoleEvents() {
    this._sightcallConsole.onACDRequestReceived(async () => {
      this._callIncoming();
    });
    this._sightcallConsole.onConsoleRequireAuth(async () => {
      this._log.info(`sightcall onConsoleRequireAuth`);
      this._authenticateSightcallConsole();
    });
    this._sightcallConsole.onACDRequestDeclined(() => {
      this._log.info(`sightcall onACDRequestDeclined`);
      this._callEnded();
    });
    this._sightcallConsole.onACDRequestAccepted((call) => {
      this._log.info(`sightcall onACDRequestAccepted`);
      //this._callStarted(call);
    });
    this._sightcallConsole.onCallCreated((call) => {
      this._log.info(`sightcall onCallCreated - reference: ${call.reference}`);
      this._callStarted(call);
    });
    this._sightcallConsole.onCallTerminated(() => {
      this._log.info(`sightcall onCallTerminated`);
      this._callEnded();
    });
    this._sightcallConsole.onAgentConnected((agent) => {
      this._log.info(`sightcall onAgentConnected - agent: ${agent}`);
      this._sightcallAgentConnected(agent);
    });
    this._sightcallConsole.onAgentDisconnected(() => {
      this._log.info(`sightcall onAgentDisconnected`);
      this._sightcallAgentDisconnected();
    });
    this._sightcallConsole.onConsoleOpened(() => {
      this._log.info(`sightcall onConsoleOpened`);
      this._sightcallConsoleOpened();
    });
    this._sightcallConsole.onConsoleClosed(() => {
      this._log.info(`sightcall onConsoleClosed`);
      this._sightcallConsoleClosed();
    });
    this._sightcallConsole.onPluginMissing(() => {
      this._log.info(`sightcall onPluginMissing`);
      this._state.sightcallConsole.error = SightcallError.pluginMissing;
      this._notifyStateChanged({ ...this._state });
    });
    this.sightcallConsole.onPluginUpdate(() => {
      this._log.info("sightcall onPluginUpdate");
    });
    this.sightcallConsole.onPluginStartedUpdate(() => {
      this._log.info("sightcall onPluginStartedUpdate");
    });
    this.sightcallConsole.onPluginRequiresPermission(() => {
      this._log.info("sightcall onPluginRequiresPermission");
    });
    this._sightcallConsole.onExtensionMissing((args) => {
      this._log.info(
        `sightcall onExtensionMissing - extensionUrl: ${args.extensionUrl}`
      );
      this._state.sightcallConsole.error = SightcallError.extensionMissing;
      this._notifyStateChanged({ ...this._state });
    });
    this._sightcallConsole.onRtccCoreDumpReceived((dump) => {
      this._log.info(`sightcall onRtccCoreDumpReceived`);
      this._sightcallDumpReceived(dump);
    });
  }

  private _subscribeToI18nEvents() {
    i18n.on("languageChanged", (language) => {
      this._log.info(`language changed to ${language}`);
      this._languageChanged(language);
    });
  }
}

export enum SightcallError {
  // The user doesnt exist in the sightcall backend
  userDoesNotExist,
  // The currently logged in sightcall user does not match the logged in JURA user
  userMismatch,
  // The sightcall plugin is missing
  pluginMissing,
  // The sightcall extension is missing
  extensionMissing,
  //multiple logins of the same user
  multipleLogins,
}
