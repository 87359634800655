import css from "./OptionsDisplay.module.css";

export interface OptionsDisplayProps {
  selectedIndex: number;
  values: string[];
}
function OptionsDisplay(props: OptionsDisplayProps): JSX.Element {
  console.log(props.values);
  let values = props.values;

  if (values.length < 2) {
    throw Error("Need at least two elements!");
  }

  let first = values[0];
  let last = values[values.length - 1];
  let middleValues = values.slice(1, -1);
  let basis = values.length * 2 - 2;
  return (
    <div className="">
      <div className="flex flex-row relative isolate justify-between items-center">
        {/* The track */}
        <div className="h-full absolute left-2 right-2 z-[-1] flex flex-col justify-center">
          <div className="h-1 bg-jura-chrome-15"></div>
        </div>
        {/* The knobs */}
        {values.map((value, index) => {
          if (index == props.selectedIndex) {
            return (
              <div className="h-4 w-4 bg-jura-brown-60 rounded-full"></div>
            );
          } else {
            return (
              <div className="h-4 w-4 bg-jura-chrome-15 rounded-full"></div>
            );
          }
        })}
      </div>
      {/* The texts */}
      <div className=" flex flex-row">
        {/* First text */}
        <div className=" flex-1" style={{ maxWidth: `${(1 / basis) * 100}%` }}>
          <div className="flex flex-row justify-start">
            <div
              className={`break-words inline-block max-w-full text-start text-sm ${
                0 == props.selectedIndex ? "font-bold" : null
              }`}
            >
              {first}
            </div>
          </div>
        </div>
        {/* Middle texts */}
        {middleValues.map((value, index) => {
          return (
            <div
              className=" flex-1"
              style={{ maxWidth: `${(2 / basis) * 100}%` }}
            >
              <div className="flex flex-row justify-center ">
                <div
                  className={`break-words inline-block max-w-full text-center text-sm ${
                    index + 1 == props.selectedIndex ? "font-bold" : null
                  }`}
                >
                  {value}
                </div>
              </div>
            </div>
          );
        })}

        {/* Last text */}
        <div className=" flex-1" style={{ maxWidth: `${(1 / basis) * 100}%` }}>
          <div className="flex flex-row justify-end ">
            <div
              className={`break-words inline-block max-w-full text-end text-sm ${
                values.length - 1 == props.selectedIndex ? "font-bold" : null
              }`}
            >
              {last}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OptionsDisplay;
