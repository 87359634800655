import JuraLogo from "../../components/JuraLogo/JuraLogo";
import css from "./SplashPage.module.css";

function SplashPage(): JSX.Element {
  return (
    <div className={css.root}>
      <div className={css.imageContainer}>
        <JuraLogo />
      </div>
    </div>
  );
}

export default SplashPage;
