export enum LogLevel {
  debug = "DEBUG",
  error = "ERROR",
  info = "INFO",
}

export abstract class ILogger {
  abstract log(message: string, level: LogLevel): void;

  debug(message: string) {
    this.log(message, LogLevel.debug);
  }
  info(message: string) {
    this.log(message, LogLevel.info);
  }
  error(message: string) {
    this.log(message, LogLevel.error);
  }
  abstract dump(): string;

  protected toString(message: LogMessage): string {
    return `[${message.level}] - ${message.timestamp.toLocaleString()} - ${
      message.message
    }`;
  }
}

export class DebugLogger extends ILogger {
  messages: LogMessage[] = [];

  log(message: string, level: LogLevel): void {
    var msg: LogMessage = {
      message: message,
      level: level,
      timestamp: new Date(),
    };

    //add to message list
    this.messages.push(msg);

    //limit message list size
    if (this.messages.length > 500) {
      this.messages.pop();
    }

    //output to console
    switch (level) {
      case LogLevel.debug:
        console.debug(this.toString(msg));
        break;
      case LogLevel.info:
        console.info(this.toString(msg));
        break;
      case LogLevel.error:
        console.error(this.toString(msg));
        break;
    }
  }

  dump(): string {
    return this.messages.map((m) => this.toString(m)).join("\n");
  }
}

export class Logger extends ILogger {
  messages: LogMessage[] = [];

  log(message: string, level: LogLevel): void {
    var msg: LogMessage = {
      message: message,
      level: level,
      timestamp: new Date(),
    };

    //add to message list if appropriate
    if (level == LogLevel.error || level == LogLevel.info) {
      this.messages.push(msg);
    }

    //limit message list size
    if (this.messages.length > 500) {
      this.messages.pop();
    }

    //output to console
    switch (level) {
      case LogLevel.debug:
        //dont output debug level
        break;
      case LogLevel.info:
        console.info(this.toString(msg));
        break;
      case LogLevel.error:
        console.error(this.toString(msg));
        break;
    }
  }

  dump(): string {
    return this.messages.map((m) => this.toString(m)).join("\n");
  }
}

interface LogMessage {
  message: string;
  level: LogLevel;
  timestamp: Date;
}
