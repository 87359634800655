import Scaffold from "../Shared/Scaffold/Scaffold";
import {
  FollowTarget,
  FollowerTypeContain,
} from "../../components/Follower/Follower";
import SideMenu from "../Shared/SideMenu/SideMenu";
import PageRoutes from "../../PageRoutes";
import Header from "../Shared/Header/Header";
import { CoffeeMachineTemplates } from "../../features/coffeeMachineTemplates/init";
import { AppState, AppViewmodel, SightcallError } from "../../Application";
import { useViewmodel } from "../../utilities/viewmodel/reactviewmodel";
import Call from "./Call/Call";
import {
  ITemplateService,
  TemplateService,
} from "../../features/coffeeMachineTemplates/TemplateService";
import Footer from "../Shared/Footer/Footer";
import MaterialIcon from "../../components/MaterialIcon/MaterialIcon";
import { useTranslation } from "react-i18next";

export interface Props {
  appViewmodel: AppViewmodel;
}

function AdvisoryScreenPage({
  appViewmodel: appViewmodel,
}: Props): JSX.Element {
  const [state, viewmodel] = useViewmodel<AppState, AppViewmodel>(appViewmodel);

  return (
    <Scaffold>
      <Scaffold.Header>
        <Header
          devEnvironment={appViewmodel.config.isDevEnvironment}
          user={state.user}
        ></Header>
      </Scaffold.Header>
      <Scaffold.SideMenu>
        <SideMenu
          activeRoute={PageRoutes.AdvisoryScreenPage}
          vm={appViewmodel}
        ></SideMenu>
      </Scaffold.SideMenu>
      <Scaffold.MainContent>
        <div className="flex flex-row h-full">
          <div className="flex-1 min-w-600 h-full">
            <ConsoleArea state={state} />
          </div>
          <div
            className={["h-full", state.call == null ? "w-0" : "w-min"].join(
              " "
            )}
          >
            <CallArea
              state={state}
              templateService={viewmodel.templateService}
            />
          </div>
        </div>
      </Scaffold.MainContent>
      <Scaffold.Footer>
        <Footer
          devEnvironment={appViewmodel.config.isDevEnvironment}
          downloadDiagnostics={() => appViewmodel.downloadDiagnostics()}
          performTestCall={() => appViewmodel.performTestCall()}
        />
      </Scaffold.Footer>
    </Scaffold>
  );
}

function LoadingConsole(): JSX.Element {
  return (
    <div className="h-full w-full bg-jura-chrome-15">
      <div className="w-full h-14 bg-jura-chrome-60 flex flex-row items-center gap-2 px-2">
        <div className="rounded-full h-10 w-10 bg-jura-chrome-15 animate-pulse"></div>
        <div className="rounded-sm h-1/2 w-20 bg-jura-chrome-15 animate-pulse"></div>
        <div className="flex-1"></div>
        <div className="rounded-sm h-1/2 w-20 bg-jura-chrome-15 animate-pulse"></div>
        <div className="rounded-sm h-1/2 w-20 bg-jura-chrome-15 animate-pulse"></div>
        <div className="rounded-sm h-1/2 w-10 bg-jura-chrome-15 animate-pulse"></div>
      </div>
    </div>
  );
}

function LoadingCallData(): JSX.Element {
  return (
    <div className="flex flex-col gap-2 p-2 min-w-[25rem]">
      <div className="bg-jura-chrome-15 h-20 animate-pulse"></div>
      <div className="flex flex-row gap-2">
        <div className="bg-jura-chrome-15 flex-1 h-40 w-40 animate-pulse"></div>
        <div className="bg-jura-chrome-15 flex-1 h-40 w-40 animate-pulse"></div>
      </div>
      <div className="flex flex-row gap-2">
        <div className="bg-jura-chrome-15 flex-1 h-40 w-40 animate-pulse"></div>
        <div className="bg-transparent flex-1 h-40 w-40 animate-pulse"></div>
      </div>
    </div>
  );
}

function CallArea({
  state,
  templateService,
}: {
  state: AppState;
  templateService: ITemplateService;
}) {
  if (state.call == null) {
    return null;
  } else if (state.call?.isLoadingData == true) {
    return <LoadingCallData />;
  } else if (state.call?.isLoadingData == false) {
    return (
      <Call
        user={state.call.user}
        coffeeMachine={state.call.coffeeMachine}
        joe={state.call.joe}
        otherCoffeeMachines={state.call.otherCoffeeMachines}
        templateService={templateService}
      />
    );
  } else {
    throw "Not handling all possible states!";
  }
}

function ConsoleArea({ state }: { state: AppState }) {
  if (state.sightcallConsole.error != null) {
    return <SightcallConsoleError error={state.sightcallConsole.error!} />;
  }

  switch (state.sightcallConsole.state) {
    case "open":
      if (state.sightcallConsole.signedIn) {
        return <FollowTarget tag="sightcall-tag" type={FollowerTypeContain} />;
      }
      return <LoadingConsole />;
    case "opening":
      return <LoadingConsole />;
    case "closed":
      return state.sightcallConsole.error != null ? (
        <SightcallConsoleError error={state.sightcallConsole.error!} />
      ) : null;
    default:
      throw "Not handling all possible states!";
  }
}

function SightcallConsoleError({
  error,
}: {
  error: SightcallError;
}): JSX.Element {
  const [t, i18n] = useTranslation("translation", { keyPrefix: "errors" });

  const renderSwitch = () => {
    //TODO: Proper translations
    switch (error) {
      case SightcallError.userDoesNotExist:
        return <div>{t("Sightcall user not authorized")}</div>;
      case SightcallError.userMismatch:
        return <div>{t("Sightcall user mismatch")}</div>;
      case SightcallError.extensionMissing:
        return <div>{t("Sightcall extension missing")}</div>;
      case SightcallError.pluginMissing:
        return <div>{t("Sightcall plugin missing")}</div>;
      case SightcallError.multipleLogins:
        return <div>{t("Sightcall multiple logins")}</div>;
      default:
        console.log("not handling error" + error);
        return <div></div>;
    }
  };
  return (
    <div className="h-full w-full  flex flex-col bg-jura-chrome-15">
      <div className="w-full h-14 bg-jura-chrome-60 flex flex-row items-center gap-2 px-2">
        <div className="rounded-full h-10 w-10 bg-jura-chrome-15"></div>
        <div className="rounded-sm h-1/2 w-20 bg-jura-chrome-15"></div>
        <div className="flex-1"></div>
        <div className="rounded-sm h-1/2 w-20 bg-jura-chrome-15"></div>
        <div className="rounded-sm h-1/2 w-20 bg-jura-chrome-15"></div>
        <div className="rounded-sm h-1/2 w-10 bg-jura-chrome-15"></div>
      </div>
      <div className="flex-1 flex flex-row justify-center items-center">
        <div className="bg-white p-4 flex flex-col items-center gap-4">
          <MaterialIcon icon="warning" />
          {renderSwitch()}
        </div>
      </div>
    </div>
  );
}

export default AdvisoryScreenPage;
