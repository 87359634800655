import { AppConfig } from "./Application";

export const applicationConfig: AppConfig = {
  verbose: true,
  sightcallConsoleIframeId: "sightcall.console.iframe.id",
  joeApiBaseUrl: process.env.REACT_APP_JOE_API_URL ?? "",
  isDevEnvironment:
    process.env.REACT_APP_DEV_ENVIRONMENT == "true" ? true : false,
};

console.log(process.env.REACT_APP_DEV_ENVIRONMENT);

console.log(
  `starting application with config: ${JSON.stringify(applicationConfig)}`
);
