import { useEffect, useState } from "react";
import { Viewmodel } from "./viewmodel";

/**
 * Use this to subscribe to state changes of a viewmodel
 * @param value the viewmodel instance
 * @param shouldUpdate can be used for more fine grained UI Updates
 * @returns
 */
export function useViewmodel<TState, TViewmodel extends Viewmodel<TState>>(
  value: TViewmodel,
  shouldUpdate: ((oldState: TState, newState: TState) => boolean) | null = null
): [TState, TViewmodel] {
  const [viewmodel, setViewmodel] = useState<TViewmodel>(value);
  const [state, setState] = useState<TState>(viewmodel.getState());

  useEffect(() => {
    let update = (newState: TState) => {
      if (shouldUpdate?.(state, newState) ?? true) {
        setState(newState);
      }
    };
    viewmodel.subscribe(update);

    return () => {
      viewmodel.unsubscribe(update);
    };
  }, [viewmodel]);

  return [state, viewmodel];
}
