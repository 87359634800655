import JuraLogo from "../../../components/JuraLogo/JuraLogo";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

function Header(props: HeaderProps): JSX.Element {
  const { t, i18n } = useTranslation();

  return (
    <div className="w-full h-16 flex flex-row items-center px-6 gap-10 border-jura-chrome-16 border-b xl:pr-[15rem]">
      <div className="h-10">
        <JuraLogo></JuraLogo>
      </div>
      {props.devEnvironment && (
        <div className="text-red-600">
          DEV ENVIRONMENT - DEV ENVIRONMENT - DEV ENVIRONMENT
        </div>
      )}
      <div className="flex-1"></div>
      <div className="border h-10 px-3 flex flex-row">
        <select
          className="text-black w-full"
          value={i18n.language}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            i18n.changeLanguage(e.target.value);
          }}
        >
          {Object.keys(i18n.services.resourceStore.data).map((l) => (
            <option value={l} key={l}>
              {l.toLocaleUpperCase()}
            </option>
          ))}
        </select>
      </div>

      <div className="border h-10 flex justify-center items-center px-3 font-bold text-sm text-black">
        {props.user}
      </div>
    </div>
  );
}

export interface HeaderProps {
  user: string;
  devEnvironment: boolean;
}

export default Header;
