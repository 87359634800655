import logoImg from "./800px-Jura_Logo.png";
import css from "./JuraLogo.module.css";

function JuraLogo(): JSX.Element {
  return (
    <div className="w-full h-full">
      <img src={logoImg} className="w-full h-full object-contain"></img>
    </div>
  );
}

export default JuraLogo;
