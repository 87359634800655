import { ITokenService } from "./ITokenService";
import Keycloak from "keycloak-js";

/**
 * An Implementation of the ITokenService for Keycloak
 */
export class KeycloakTokenService implements ITokenService {
  keycloak: Keycloak;

  constructor(keycloak: Keycloak) {
    this.keycloak = keycloak;
  }

  async updateAccessToken(): Promise<void> {
    await this.keycloak.updateToken(60);
  }

  get accessToken(): string | null {
    return this.keycloak.token ?? null;
  }

  get refreshToken(): string | null {
    return this.keycloak.refreshToken ?? null;
  }

  get idToken(): string | null {
    return this.keycloak.idToken ?? null;
  }
}
