import EmptyBean from "./bean_grey.png";
import FullBean from "./bean_gold.png";

interface CoffeeStrengthSliderProps {
  total: number;
  selected: number;
}
function CoffeeStrengthSlider(props: CoffeeStrengthSliderProps): JSX.Element {
  var total = props.total;
  var selected = props.selected;
  if (selected > total) {
    selected = total;
  }
  return (
    <div className="flex flex-row justify-start h-fit w-fit gap-1">
      {Array(total)
        .fill(0)
        .map((value, index) => {
          return (
            <img
              src={index <= selected ? FullBean : EmptyBean}
              className="h-6"
            />
          );
        })}
    </div>
  );
}

export default CoffeeStrengthSlider;
