import { CSSProperties, PropsWithChildren } from "react";
import { MaterialIcon as MaterialIcons } from "material-icons";

export interface MaterialIconProps {
  style?: CSSProperties | undefined;
  icon: MaterialIcons;
}
function MaterialIcon(props: MaterialIconProps): JSX.Element {
  return (
    <div className="material-icons" style={props.style}>
      {props.icon}
    </div>
  );
}

export default MaterialIcon;
