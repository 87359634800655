import React, { PropsWithChildren } from "react";

function Scaffold(props: PropsWithChildren): JSX.Element {
  var header: any;
  var sideMenu: any;
  var mainContent: any;
  var footer: any;

  React.Children.map(props.children, (child) => {
    if (React.isValidElement(child) && typeof child.type === "function") {
      var a = child as React.ReactElement<any, any>;

      if (a.type == Scaffold.Header) {
        header = child;
      } else if (a.type == Scaffold.SideMenu) {
        sideMenu = child;
      } else if (a.type == Scaffold.MainContent) {
        mainContent = child;
      } else if (a.type == Scaffold.Footer) {
        footer = child;
      }
    }
  });

  return (
    <div className="w-screen h-screen flex flex-col">
      {header ?? <Scaffold.Header />}
      <div className="w-full flex-1 flex flex-row">
        {sideMenu ?? <Scaffold.SideMenu />}
        {mainContent ?? <Scaffold.MainContent />}
      </div>
      {footer ?? <Scaffold.Footer />}
    </div>
  );
}

Scaffold.Header = (props: PropsWithChildren): JSX.Element => {
  return (
    <div className="w-full max-w-full overflow-hidden">{props.children}</div>
  );
};

Scaffold.SideMenu = (props: PropsWithChildren): JSX.Element => {
  return (
    <div className="h-full max-h-full overflow-hidden">{props.children}</div>
  );
};

Scaffold.MainContent = (props: PropsWithChildren): JSX.Element => {
  return (
    <div className="flex-1 h-full max-h-full overflow-hidden p-2 bg-jura-cream">
      {props.children}
    </div>
  );
};

Scaffold.Footer = (props: PropsWithChildren): JSX.Element => {
  return <div className="">{props.children}</div>;
};

export default Scaffold;
