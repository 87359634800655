import { XMLParser } from "fast-xml-parser";
import { IJoeApi, JoeApi } from "../joeApi/joeApi";
import { ITokenService } from "../tokenService/ITokenService";
import { CoffeeMachineTemplate } from "./CoffeeMachineTemplate";
import { CoffeeMachineTemplateTextProvider } from "./CoffeeMachineTemplateTexts";
import { init } from "./init";

export abstract class ITemplateService {
  abstract init(): Promise<void>;
  abstract t(key: string, locale: string): string;
  abstract getTemplate(efCode: string): Promise<CoffeeMachineTemplate | null>;
}

export class TemplateService implements ITemplateService {
  _templates: Map<string, CoffeeMachineTemplate> = new Map<
    string,
    CoffeeMachineTemplate
  >();

  _texts: CoffeeMachineTemplateTextProvider | null = null;
  _joeApi: IJoeApi;

  constructor(joeApi: IJoeApi) {
    this._joeApi = joeApi;
  }

  async init(): Promise<void> {
    let result = await init();
    result.templates.forEach((t) => this._templates.set(t["@_Group"], t));

    this._texts = result.textProvider;
  }

  t(key: string, locale: string): string {
    if (this._texts == null) {
      return key;
    }
    this._texts?.setLocale(locale);
    return this._texts?.t(key) ?? key;
  }

  async getTemplate(efCode: string): Promise<CoffeeMachineTemplate | null> {
    //check the cache
    let template = this._templates.get(efCode) ?? null;

    //return early if the template was available in cache
    if (template != null) {
      console.log("cache hit!" + efCode);
      return template;
    }

    //check the api for the xml
    let xmlFile = await this._joeApi.getXMLFile(efCode);

    //return null if the api didnt have the xml
    if (xmlFile == null) {
      return null;
    }

    //parse the xml if it was available
    var xmlParser = new XMLParser({
      ignoreAttributes: false,
      isArray: (tagName, jPath, isLeafNode, isAttribute) => {
        if (tagName == "PRODUCT") return true;
        if (tagName == "LINK") return true;
        if (tagName === "PROCESS") return true;
        if (tagName === "COMBOBOX") return true;
        if (tagName === "SLIDER") return true;
        if (tagName === "SWITCH") return true;
        if (tagName === "BANK") return true;
        if (tagName === "TEXTITEM") return true;
        return false;
      },
    });
    var parsed = xmlParser.parse(xmlFile);

    //Make sure data is valid!
    if (parsed?.JOE == null || parsed?.JOE == undefined) {
      return null;
    }
    template = parsed["JOE"] as CoffeeMachineTemplate;

    //store in cache
    this._templates.set(efCode, template);

    //return template
    return template;
  }
}
