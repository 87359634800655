import Keycloak from "keycloak-js";

// Setup Keycloak instance as needed
const keycloak = new Keycloak({
  url: "https://keycloak.juraccount.com/",
  realm: "jura_external_services",
  clientId: "account",
});

export interface AuthClientTokens {
  idToken: string;
  refreshToken: string;
  accessToken: string;
}

export default keycloak;
