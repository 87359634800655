export class Viewmodel<TState> {
  protected _state: TState;
  protected _subscribers: ((state: TState) => void)[] = [];

  constructor(initialState: TState) {
    this._state = initialState;
  }

  public subscribe(onStateChanged: (state: TState) => void): void {
    this._subscribers.push(onStateChanged);
  }

  public unsubscribe(onStateChanged: (state: TState) => void): void {
    let index = this._subscribers.indexOf(onStateChanged);
    if (index < 0) {
      return;
    }
    this._subscribers.splice(index, 1);
  }

  public getState(): TState {
    return this._state;
  }

  protected _notifyStateChanged(state: TState): void {
    this._subscribers.forEach((sub) => sub(state));
  }
}
