import axios from "axios";

export abstract class ILocationApi {
  abstract getLocation(): Promise<string | undefined>;
}

export class LocationApi implements ILocationApi {
  async getLocation(): Promise<string | undefined> {
    var response = await axios.get(
      "https://rd-joe.jura-cloud.com/redirects/phonenumber.php"
    );
    var location = response.data?.response?.country_code;

    return location;
  }
}
