import Logo from "./jura_logo.png";

class NotificationService {
  private _currentNotification: Notification | null = null;

  async requestPermission(): Promise<void> {
    console.log("requesting");
    var permission = Notification.requestPermission();
  }

  async notify(title: string, message: string): Promise<void> {
    //Request browser permissions if necessary
    if (Notification.permission != "granted") {
      Notification.requestPermission();
    }

    //close the current notification if one is open (this prevents them from piling up)
    this._currentNotification?.close();

    //create and show the new notification
    var notification = new Notification(title, {
      body: message,
      badge: Logo,
      icon: Logo,
      requireInteraction: true,
    });

    //set the new notification as the current notification
    this._currentNotification = notification;

    //when the notification is clicked, show the current tab and close the notification
    notification.onclick = function (x) {
      window.focus();
      this.close();
    };
  }

  clear(): void {
    this._currentNotification?.close();
  }
}

export default NotificationService;
