export interface SwitchProps {
  value: boolean;
}
function Switch(props: SwitchProps): JSX.Element {
  var value = props.value;

  return (
    <div
      className={[
        "rounded-full  w-10 h-5 flex flex-row items-center",
        value
          ? "justify-end bg-jura-brown-60"
          : "justify-start bg-jura-brown-15",
      ].join(" ")}
    >
      {/* Track */}
      <div className="h-5 w-5 p-[3px]">
        {/* Ball */}
        <div className="rounded-full bg-jura-brown-100 w-full h-full"></div>
      </div>
    </div>
  );
}

export default Switch;
