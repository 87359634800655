import { createContext, useEffect, useRef, useState } from "react";
import keycloak, { AuthClientTokens } from "./features/keycloak/init";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import SightcallConsole from "./features/sightcall/SightcallConsole";
import { ITokenService } from "./features/tokenService/ITokenService";
import { KeycloakTokenService } from "./features/tokenService/KeycloakTokenService";
import SplashPage from "./pages/SplashPage/SplashPage";
import { Follower } from "./components/Follower/Follower";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PageRoutes from "./PageRoutes";
import AdvisoryScreenPage from "./pages/AdvisoryScreenPage/AdvisoryScreenPage";
import jwt_decode from "jwt-decode";
import UITestPage from "./pages/UITestPage/UITestPage";
import { AppConfig, AppState, AppViewmodel } from "./Application";
import { applicationConfig } from "./config";
import { useViewmodel } from "./utilities/viewmodel/reactviewmodel";
import LoadingPage from "./pages/LoadingPage/LoadingPage";

/**
 * The App before the User is logged in. Displays an <AuthenticatedApp> once the login completes
 * @returns
 */
function App() {
  const [authContext, setAuthContext] = useState<AuthContext | null>(null);

  const _onKeycloakEvent = (event: unknown, error: unknown) => {
    console.log("onKeycloakEvent", event, error);
  };

  const _onKeycloakToken = (tokens: any) => {
    console.log("onTokenEvent", tokens);

    if (
      tokens.idToken == undefined ||
      tokens.refreshToken == undefined ||
      tokens.token == undefined
    ) {
      setAuthContext(null);
      return;
    }

    let authClientTokens: AuthClientTokens = {
      idToken: tokens.idToken,
      refreshToken: tokens.refreshToken,
      accessToken: tokens.token,
    };

    var idTokenDecoded = jwt_decode(tokens.idToken) as any;

    setAuthContext({
      user: idTokenDecoded.email,
      tokenService: new KeycloakTokenService(keycloak),
      logout: () => keycloak.logout(),
    });
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onEvent={_onKeycloakEvent}
      onTokens={_onKeycloakToken}
      initOptions={{
        onLoad: "login-required",
      }}
    >
      {authContext == null ? (
        <SplashPage />
      ) : (
        <AuthenticatedApp authContext={authContext!} />
      )}
    </ReactKeycloakProvider>
  );
}

export interface AuthContext {
  user: string;
  tokenService: ITokenService;
  logout: () => void;
}

export interface AuthenticatedAppProps {
  authContext: AuthContext;
}

/**
 * The App once the user is logged in
 * @returns
 */
function AuthenticatedApp({ authContext }: AuthenticatedAppProps) {
  const [state, viewmodel] = useViewmodel<AppState, AppViewmodel>(
    new AppViewmodel(applicationConfig, authContext, "1.0.12")
  );

  //Initialise the application
  useEffect(() => {
    viewmodel.init();
  }, [viewmodel]);

  return (
    <div className="w-screen h-screen">
      {/* Sightcall iframe must live at the root of the application as it must always exist! */}
      {/* The follower allows the iframe to be moved and hidden */}
      <Follower tag="sightcall-tag">
        <iframe
          id={applicationConfig.sightcallConsoleIframeId}
          style={{ width: "100%", height: "100%" }}
          allow="camera"
          allowFullScreen
        ></iframe>
      </Follower>
      {/* Only show the application content when all resources are ready  */}
      {state.isInitialised ? (
        <BrowserRouter>
          <Routes>
            <Route //
              path={PageRoutes.AdvisoryScreenPage}
              element={<AdvisoryScreenPage appViewmodel={viewmodel} />}
            />
            <Route //
              path={"uitest"}
              element={<UITestPage />}
            />
            <Route //
              path={"/"}
              element={<AdvisoryScreenPage appViewmodel={viewmodel} />}
            />
          </Routes>
        </BrowserRouter>
      ) : (
        <LoadingPage percent={state.percentInitialised} />
      )}
    </div>
  );
}

export default App;
